export const PATH_PARAMS = {
  SELECTED_GAME_WEEK: "gw",
  SELECTED_PHASE: "phase",
  LEAGUE_ID: "leagueId",
  TEAM_ID: "teamId",
  CATEGORY_ID: "categoryId",
  HIGHLIGHT_ID: "highlightId",
  FIXTURES_ID: "fixtureId",
} as const;

export const QUERY_PARAMS = {
  FUTURE_GAME_WEEK: "fgw",
  TRANSFERS_INFO: "transfers-info",
  POINTS_INFO: "points-info",
  OVERALL_INFO: "overall-info",
  SHARED: "shared",
  SHARED_USER_TEAM_ID: "shared-user-team-id",
  EPL_PLAYER_INFO_MODAL: "epl-player-info-modal",
};

const BASE_PATH = `/leagues/:${PATH_PARAMS.LEAGUE_ID}`;

export const ROUTES = {
  /* Auth Routes */
  LANDING_PAGE: "/",
  INTRO_PAGE: "/intro",
  REGISTER_PAGE: "/register",
  SIGN_IN_PAGE: "/sign-in",
  TEAM_ID_PAGE: "/team-id",
  FORGOT_PASSWORD_PAGE: "/forget-password",
  RESET_PASSWORD_PAGE: "/reset-password",
  GET_STARTED_PAGE: "/get-started",
  OFFICIAL_LOGIN_PAGE: "/official-login",

  /* Pages */
  BASE_HOME_PATH: "/home",
  HOME_PATH: `/home/:${PATH_PARAMS.LEAGUE_ID}`,
  HIGHLIGHTS_PATH: BASE_PATH + `/highlights`,
  HIGHLIGHTS_CATEGORY_PATH: `${BASE_PATH}/highlights/:${PATH_PARAMS.CATEGORY_ID}`,
  SINGLE_HIGHLIGHTS_PATH: `${BASE_PATH}/highlights/:${PATH_PARAMS.CATEGORY_ID}/:${PATH_PARAMS.HIGHLIGHT_ID}`,

  /* Fixtures Pages */
  FIXTURES_PATH: `${BASE_PATH}/fixtures`,
  FIXTURE_RESULTS_PATH: `${BASE_PATH}/fixtures/:${PATH_PARAMS.FIXTURES_ID}`,
  FIXTURE_RESULTS_OVERVIEW_PATH: `${BASE_PATH}/fixtures/:${PATH_PARAMS.FIXTURES_ID}/overview`,
  FIXTURE_RESULTS_OWNERSHIP_PATH: `${BASE_PATH}/fixtures/:${PATH_PARAMS.FIXTURES_ID}/ownership`,

  TEAM_PAGE: `${BASE_PATH}/team/:${PATH_PARAMS.TEAM_ID}`,
  PICK_TEAM_PATH: `${BASE_PATH}/pick-team/:${PATH_PARAMS.TEAM_ID}`,
  MAKE_TRANSFERS_PATH: `${BASE_PATH}/make-transfers/:${PATH_PARAMS.TEAM_ID}`,
  TRANSFERS_TABLE_PATH: `${BASE_PATH}/make-transfers/:${PATH_PARAMS.TEAM_ID}/transfers-table`,
  MY_TEAM_PAGE: `${BASE_PATH}/team`,

  /* Leagues Page */
  MINI_LEAGUE_STANDINGS_PAGE: `${BASE_PATH}`,
  MINI_LEAGUE_CAPTAINS_PAGE: `${BASE_PATH}/captains`,
  MINI_LEAGUE_MOST_HURTFUL_PAGE: `${BASE_PATH}/most-hurtful`,
  MINI_LEAGUE_NEW_ENTRIES_PAGE: `${BASE_PATH}/new-entries`,

  /* Make Transfers Pages */
  TRANSFERS_PATH: `${BASE_PATH}/transfers`,
  MANAGER_TRANSFERS_PATH: `${BASE_PATH}/transfers/:${PATH_PARAMS.TEAM_ID}`,

  /* Privacy Policy and Terms of services */
  PRIVACY_POLICY_PAGE: "/privacy-policy",
  TERMS_OF_SERVES_PAGE: "/terms-of-service",
  COOKIES_POLICY_PAGE: "/cookies-policy",
  CONTACT_US_PAGE: "/contact-us",
} as const;
