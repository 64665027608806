import { css } from "@emotion/react";
import { Modal, Stack, Typography } from "@mui/material";
import { bgColor, secondaryColor } from "../../../config/theme";
import { IMiniLeagueMostHurtfulRes } from "../../../utils/hooks/useGetMiniLeagueMostHurtful";
import { AppTableTeamCell, Button, ChangeIndicator } from "../../../components";
import { customScrollbar, transition_all } from "../../../config/styles";
import { useGetUrl } from "../../../utils/hooks/useGetUrl";
import { Link } from "react-router-dom";

interface IManagerListModal {
  open: boolean;
  managersList: IMiniLeagueMostHurtfulRes["owned_by"];
  playerName: IMiniLeagueMostHurtfulRes["name"] | string;
  isCaptain?: boolean;
  showRank?: boolean;
  onClose(): void;
}

/**
 * A pop up model that render info in a grid
 * Takes an info object and renders a label using the key and a value using the value
 * of the info object
 */

export const ManagerListModal = (props: IManagerListModal) => {
  const { getTeamViewPath } = useGetUrl();

  const playerName =
    typeof props.playerName === "string"
      ? props.playerName
      : `${props.playerName.first_name} ${props.playerName.last_name}`;

  return (
    <Modal open={props.open} css={modalRootStyles} onClose={props.onClose}>
      <Stack css={modalStyles}>
        <div css={headerContainerStyle}>
          <Typography css={headerStyle}>
            Managers that {props.isCaptain ? "captained" : "own"}{" "}
            <span css={playerNameStyle}>{playerName}</span>
          </Typography>
        </div>
        <Stack component={"ul"} css={managerListStyle}>
          {props.managersList.map((manager, index) => (
            <Link key={manager.entry} to={getTeamViewPath(manager.entry)}>
              <li>
                {!props.showRank ? (
                  <span>{index + 1}</span>
                ) : (
                  <Stack component={"span"}>
                    <ChangeIndicator
                      prevValue={manager.last_rank}
                      currentValue={manager.rank}
                    />
                    {manager.rank}
                  </Stack>
                )}

                <span>
                  <AppTableTeamCell
                    mangerName={manager.player_name}
                    teamName={manager.entry_name}
                  />
                </span>
              </li>
            </Link>
          ))}
        </Stack>

        <div css={buttonContainerStyle}>
          <Button button="solid" color="white" onClick={props.onClose}>
            Back
          </Button>
        </div>
      </Stack>
    </Modal>
  );
};
const modalRootStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
`;

const modalStyles = css`
  background: ${bgColor};
  z-index: -1;

  border-radius: 1.5rem;
  width: 100%;
  max-width: 500px;
`;

const buttonContainerStyle = css`
  padding: 1.5rem;
  button {
    color: black;
    font-weight: 700;

    :hover {
      background: #ffffffec;
    }
  }
`;

const managerListStyle = css`
  ${customScrollbar}
  max-height: 20rem;
  overflow-y: scroll;

  li {
    ${transition_all}
    display: flex;
    gap: 1rem;
    padding: 1rem;
    border-bottom: 1px solid #97979720;

    :first-of-type {
      border-top: 1px solid #97979720;
    }

    & > *:first-of-type {
      /* flex: 0.5; */
      text-align: center;
      font-size: 1rem;
      font-weight: 400;
      align-items: center;
      justify-self: center;
      flex-direction: row;
      gap: 0.5rem;
      padding-inline: 1rem;
    }

    & > *:nth-of-type(2) {
      flex: 2;
    }
  }
`;

const headerContainerStyle = css`
  padding: 1.5rem;
`;

const headerStyle = css`
  font-size: 1.5rem;
  font-weight: 500;
`;

const playerNameStyle = css`
  color: ${secondaryColor};
`;
