import { Stack } from "@mui/material";
import {
  EplPlayerModalHeader,
  EplPlayerPickActions,
  EplPlayerStats,
  EplPlayerTransferActions,
} from ".";
import { AppModal } from "../../../components";
import { css } from "@emotion/react";
import { useState } from "react";

interface IEplPlayerPickModal {
  open: boolean;
  onClose: () => void;
  isTransfer?: boolean;
  isPickTeam?: boolean;
}

export const EplPlayerPickModal = (props: IEplPlayerPickModal) => {
  const [showShadow, setShowShadow] = useState(false);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    setShowShadow(e.currentTarget.scrollTop > 0);
  };

  return (
    <AppModal open={props.open} onClose={props.onClose}>
      <Stack css={rootStyles}>
        <div css={[headerStyles, showShadow && headerShadowStyles]}>
          <EplPlayerModalHeader />
        </div>
        <Stack css={modalContentStyles} onScroll={(e) => handleScroll(e)}>
          {props.isTransfer && <EplPlayerTransferActions />}
          {props.isPickTeam && <EplPlayerPickActions />}
          <EplPlayerStats />
        </Stack>
      </Stack>
    </AppModal>
  );
};

const rootStyles = css`
  gap: 0.5rem;
  overflow-y: hidden;
`;

const headerStyles = css`
  padding-bottom: 1rem;
  transition: box-shadow 0.15s ease-in-out;
`;

const headerShadowStyles = css`
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.346);
`;

const modalContentStyles = css`
  gap: 1rem;
  overflow-y: auto;
`;
