import { useEffect } from "react";
import { useGetUrl } from "../utils/hooks/useGetUrl";
import { useUserContext } from "../context/UserContext";
import { useNavigateState } from "../utils/hooks/useNavigateState";
import { getCookies } from "../utils/cookies.utils";

export const OfficialAuthPagesGuard = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { user } = useUserContext();
  const { getTeamViewPath } = useGetUrl();
  const navigate = useNavigateState();
  const cookies = getCookies();

  useEffect(() => {
    if (user && cookies.signedIn !== "true") {
      navigate(getTeamViewPath(user.teamId));
    }
  }, [user]);

  return <>{children}</>;
};
