import { Box, Stack, Typography } from "@mui/material";
import { css } from "@emotion/react";
import { redColor } from "../../../config/theme";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { EPL_PLAYER_SUMMARY } from "../../../constants/view-team-pages.constants";
import { AppModal, Button } from "../../../components";
import { ITeamInfo, useGetTeamInfo } from "../../../utils/hooks/useGetTeamInfo";
import {
  EplPlayerFixtureSummary,
  EplPlayerModalHeader,
  EplPlayerStats,
  EplStatsTable,
} from ".";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { PATH_PARAMS } from "../../../config/routes";

interface IEplPlayerSummary {
  open: boolean;
  onClose(): void;
  eplPlayer: ITeamInfo["picks"][0] | null;
}

/** Renders a modal that display the player stats, fixture and Ownership in league */
export const EplPlayerSummary = (props: IEplPlayerSummary) => {
  const urlParams = useParams();
  const { teamInfo } = useGetTeamInfo({
    teamId: urlParams[PATH_PARAMS.TEAM_ID]!,
  });
  const [isShowMore, setIsShowMore] = useState(false);

  const stats = teamInfo?.picks
    .find((i) => i.element === props.eplPlayer?.element)!
    .explain.at(-1);

  const handleShowMoreBtnClick = () => {
    setIsShowMore(true);
  };

  const [showShadow, setShowShadow] = useState(false);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    setShowShadow(e.currentTarget.scrollTop > 0);
  };

  return (
    <AppModal open={props.open} onClose={props.onClose}>
      <Stack css={rootStyles}>
        {/* Header */}
        <div css={[headerStyles, showShadow && headerShadowStyles]}>
          <EplPlayerModalHeader />
        </div>

        <Stack css={modalContentStyles} onScroll={(e) => handleScroll(e)}>
          {!isShowMore ? (
            <Box paddingInline={"1rem"}>
              {stats ? (
                <>
                  <EplPlayerFixtureSummary fixtureId={stats?.fixture} />
                  <EplStatsTable stats={stats?.stats} />
                </>
              ) : (
                <NoStats />
              )}
              <Button
                button="gradient"
                css={btnStyles}
                onClick={handleShowMoreBtnClick}
              >
                {EPL_PLAYER_SUMMARY.BTN}
              </Button>
            </Box>
          ) : (
            <EplPlayerStats />
          )}
        </Stack>
      </Stack>
    </AppModal>
  );
};

const NoStats = () => {
  return (
    <Stack css={noStatsRootStyles}>
      <CloseRoundedIcon />
      <Typography>{"No fixture this Game week"}</Typography>
    </Stack>
  );
};

const rootStyles = css`
  gap: 0.5rem;
  overflow-y: hidden;
`;

const btnStyles = css`
  font-size: clamp(1rem, 2vw, 1.3rem);
`;

const noStatsRootStyles = css`
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;

  svg {
    font-size: 80px;
    color: ${redColor};
  }

  p {
    font-size: clamp(1.1rem, 2vw, 1.3rem);
  }
`;

const headerStyles = css`
  padding-bottom: 1rem;
  transition: box-shadow 0.15s ease-in-out;
`;

const headerShadowStyles = css`
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.346);
`;

const modalContentStyles = css`
  gap: 1rem;
  overflow-y: auto;
`;
