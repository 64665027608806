import {
  IPositionedPlayers,
  ITransfersTeamPositionedPlayers,
} from "./types/types";

/**
 * Arrange a team's players on a football field, given their respective positions,
 * and group the rest as subs. Returns an object with positions as keys                              and an array of players as values.
 */
export const arrangePlayersWithSubsOnFootBallField = <
  T extends { field_position: string }
>(
  teamPlayers: T[]
) => {
  let positionedPlayerTemp: { [key: string]: T[] } = {};

  teamPlayers.slice(0, 11).map((teamPlayer) => {
    if (positionedPlayerTemp[teamPlayer.field_position]) {
      positionedPlayerTemp[teamPlayer.field_position].push(teamPlayer);
    } else {
      positionedPlayerTemp[teamPlayer.field_position] = [teamPlayer];
    }
  });
  positionedPlayerTemp["SUBS"] = teamPlayers.slice(11);

  return positionedPlayerTemp;
};

export const arrangePlayerInPickTeam = <T extends { field_position: string }>(
  teamPlayers: T[]
) => {
  let positionedPlayerTemp: IPositionedPlayers<T[]> = {
    GK: [],
    DEF: [],
    MID: [],
    ATT: [],
    SUBS: [],
  };

  teamPlayers.slice(0, 11).map((teamPlayer) => {
    positionedPlayerTemp[
      teamPlayer.field_position as keyof typeof positionedPlayerTemp
    ].push(teamPlayer);
  });

  positionedPlayerTemp["SUBS"] = teamPlayers.slice(11);
  return positionedPlayerTemp;
};

export const arrangePlayerInTransfersTeam = <
  T extends { field_position: string }
>(
  teamPlayers: T[]
) => {
  let positionedPlayerTemp: ITransfersTeamPositionedPlayers<T[]> = {
    GK: [],
    DEF: [],
    MID: [],
    ATT: [],
  };

  teamPlayers.map((teamPlayer) => {
    positionedPlayerTemp[
      teamPlayer.field_position as keyof typeof positionedPlayerTemp
    ].push(teamPlayer);
  });

  return positionedPlayerTemp;
};
