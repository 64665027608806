import { css } from "@emotion/react";
import { Box, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import midField from "../../../assets/view-team/mid-field.svg";
import { bgColor } from "../../../config/theme";
import { FootballField, Player } from "../../../components";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { ITeamInfo } from "../../../utils/hooks/useGetTeamInfo";
import { arrangePlayersWithSubsOnFootBallField } from "../../../utils/arrangePlayersOnFootBallField";
import { FPL_CHIPS_KEYS_TYPE } from "../../../constants/app.constants";
import { useTeamContext } from "../../../context/TeamContext";
import { TEAMS_PAGE_TABS } from "../../../constants/view-team-pages.constants";
import { useGetFplTeamFieldTabsData } from "../../../utils/hooks/useGetFplTeamFieldTabsData";
interface ITeamFormation {
  teamPlayers: ITeamInfo["picks"];
  isTripleCapChipPlayed: boolean;
  eplPlayerClick(eplPlayer: ITeamInfo["picks"][0]): void;
  activeChip: FPL_CHIPS_KEYS_TYPE | null;
}

interface IPositionedPlayers {
  [key: string]: ITeamInfo["picks"];
}

/**
 * Render the team Formation field
 */

export const TeamFormation = (props: ITeamFormation) => {
  const [positionedPlayer, setPickTeamPositionedPlayers] =
    useState<IPositionedPlayers>({});
  const { selectedTab } = useTeamContext();

  const { fplTeamFieldTabsData } = useGetFplTeamFieldTabsData();
  const teamFieldTabsData = fplTeamFieldTabsData?.fplTeamFieldTabsData;
  const leagueSize = fplTeamFieldTabsData?.leagueSize;

  useEffect(() => {
    setPickTeamPositionedPlayers(
      arrangePlayersWithSubsOnFootBallField(props.teamPlayers)
    );
  }, [props.teamPlayers]);

  return (
    <FootballField activeChips={props.activeChip}>
      {Object.entries(positionedPlayer).map(
        ([position, playersType], playerRowIndex) => (
          <Stack component={"li"} key={position} css={playerRowStyle}>
            {playerRowIndex === 4 ? (
              <img src={midField} alt="mid Field" />
            ) : null}
            {playersType.map((player) => (
              <Box
                key={player.element}
                onClick={() => props.eplPlayerClick(player)}
                css={playerContainerStyles}
              >
                <Player
                  name={player.name}
                  isCaptain={player.is_captain}
                  isViceCaptain={player.is_vice_captain}
                  isTripleCapChipPlayed={props.isTripleCapChipPlayed}
                  fieldPosition={player.field_position}
                  points={
                    player.gw_total_points *
                    (playerRowIndex === 4 ? 1 : player.multiplier)
                  }
                  playerShirt={
                    player.field_position === "GK"
                      ? player.gk_shirt_link
                      : player.shirt_link
                  }
                  ownerShip={
                    selectedTab === TEAMS_PAGE_TABS.OWNERSHIP &&
                    teamFieldTabsData &&
                    `${
                      teamFieldTabsData[player.element].ownership
                    } / ${leagueSize}`
                  }
                  fixturesDifficulty={
                    selectedTab === TEAMS_PAGE_TABS.UPCOMING &&
                    teamFieldTabsData &&
                    teamFieldTabsData[player.element].upcomingFixtures
                  }
                  history={
                    selectedTab === TEAMS_PAGE_TABS.HISTORY &&
                    teamFieldTabsData &&
                    teamFieldTabsData[player.element].pointsHistory
                  }
                >
                  {(player.player_in || player.player_out) && (
                    <span css={subBadgeStyles(player.player_out)}>
                      {player.sub_index + 1} <KeyboardArrowRightIcon />
                    </span>
                  )}

                  {playerRowIndex === 4 ? (
                    <span css={fieldPositionBadgeStyle}>
                      {player.field_position}
                    </span>
                  ) : null}
                </Player>
              </Box>
            ))}
          </Stack>
        )
      )}
    </FootballField>
  );
};

const badgeStyle = css`
  --width: clamp(1.2rem, 3vw, 2rem);
  display: block;
  position: absolute;
  right: -0.25rem;
  top: -0.25rem;
  width: var(--width);
  line-height: var(--width);
  text-align: center;
  aspect-ratio: 1/1;
  border-radius: 99999px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: clamp(0.75rem, 2vw, 1.12rem);
  font-weight: 600;
  color: ${bgColor};
`;

const fieldPositionBadgeStyle = css`
  ${badgeStyle}
  right: auto;
  left: -0.25rem;
  top: -1rem;
  font-size: clamp(0.75rem, 3vw, 1rem);
  font-weight: 800;
`;

const subBadgeStyles = (isSubbedOut: boolean) => css`
  ${badgeStyle}
  right: auto;
  left: -0.25rem;
  top: 0.25rem;
  background-color: #0f830f;
  color: white;
  border-radius: 25%;
  width: fit-content;
  aspect-ratio: auto;
  padding-left: 0.5rem;

  svg {
    width: 1rem;
  }

  ${isSubbedOut &&
  css`
    background-color: #d23333;
    flex-direction: row-reverse;
    padding-right: 0.5rem;
    padding-left: 0;
    svg {
      rotate: 180deg;
    }
  `}
`;

const playerRowStyle = css`
  flex: 1;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding-block: clamp(0.7rem, 1vw, 1.5rem);

  &:last-of-type {
    position: relative;
    z-index: -100;
    padding-block: clamp(1.2rem, 2.5vw, 3rem);
    background-color: #f0f8ff7e;

    & > img:first-of-type {
      position: absolute;
      z-index: -1;
      top: 0;
      translate: 0 -50%;
      width: 100%;
    }
  }
`;

const playerContainerStyles = css`
  cursor: pointer;
`;
