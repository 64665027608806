import { useUserContext } from "../context/UserContext";
import { AppTabsBar } from ".";
import { VIEW_TEAM_PAGE } from "../constants/view-team-pages.constants";
import { getCookies } from "../utils/cookies.utils";
import { useLocation, useParams } from "react-router-dom";
import { useGetUrl } from "../utils/hooks/useGetUrl";
import { useTeamContext } from "../context/TeamContext";
import { useNavigateState } from "../utils/hooks/useNavigateState";
import { SESSION_STORAGE } from "../config/app.config";

export const TeamNavigationTabs = () => {
  const { user } = useUserContext();
  const { pathname, search } = useLocation();
  const { getTeamViewPath, getMakeTransfersPath, getPickTeamPath } =
    useGetUrl();

  const cookies = getCookies();
  const { teamId } = useParams();
  const { setShowLoginForm } = useTeamContext();
  const navigate = useNavigateState();

  const handleTabsChange = (_: React.SyntheticEvent, value: string) => {
    if (cookies.signedIn !== "true") {
      sessionStorage.setItem(SESSION_STORAGE.URL_HISTORY, value);
      setShowLoginForm(true);
    } else {
      navigate(value);
    }
  };

  // for shared team pages
  if (!user || String(user.teamId) !== teamId) return null;

  return (
    <AppTabsBar
      value={pathname + search}
      onTabsChange={handleTabsChange}
      tabs={[
        {
          label: VIEW_TEAM_PAGE.NAV_BUTTONS.VIEW_TEAM,
          value: getTeamViewPath(user.teamId),
        },
        {
          label: VIEW_TEAM_PAGE.NAV_BUTTONS.PICK_TEAM,
          value: getPickTeamPath(),
          state: { page: getPickTeamPath() },
        },
        {
          label: VIEW_TEAM_PAGE.NAV_BUTTONS.MAKE_TRANSFERS,
          value: getMakeTransfersPath(),
          state: { page: getMakeTransfersPath() },
        },
      ]}
    />
  );
};
