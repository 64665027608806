import { useLocation } from "react-router-dom";
import { useNavigateState } from "../utils/hooks/useNavigateState";
import { getCookies } from "../utils/cookies.utils";
import { appCrypto } from "../config/jsencrypt.config";
import { OfficialLoginFormType, SignInYupSchema } from "../utils/validation";
import { loginToOfficialFPl } from "../utils/official-auth.utils";
import { Form, Formik } from "formik";
import {
  Accordion,
  AppLogoDivider,
  Button,
  Checkbox,
  ErrorMessage,
  Input,
} from ".";
import {
  OFFICIAL_LOGIN_PAGE_CONTENT,
  SIGN_IN_PAGE_CONTENT,
} from "../constants/auth-pages.constants";
import { css } from "@emotion/react";
import password from "../assets/icons/password.svg";
import message from "../assets/icons/message.svg";
import { container, formSubmitStyles, mq } from "../config/styles";
import { Typography } from "@mui/material";
import { secondaryColor } from "../config/theme";
import { FormContainer } from "../pages/auth-pages/components";
import { SESSION_STORAGE } from "../config/app.config";
import {
  GA4_EVENTS,
  TrackGoogleAnalyticsEvent,
} from "../utils/google-analytics";
import { useTeamContext } from "../context/TeamContext";
import { isAxiosError } from "axios";

export const OfficialLoginSection = () => {
  return (
    <FormContainer extraCss={extraStyles}>
      <main css={root}>
        <div css={heading}>
          <Typography variant="h1">
            {OFFICIAL_LOGIN_PAGE_CONTENT.TITLE}
          </Typography>
          <Typography>{OFFICIAL_LOGIN_PAGE_CONTENT.SUBTITLE}</Typography>
        </div>

        <AppLogoDivider />

        <OfficialLoginForm />

        <Accordion title={OFFICIAL_LOGIN_PAGE_CONTENT.DROP_DOWN.TITLE}>
          <ol css={stepsStyles}>
            {OFFICIAL_LOGIN_PAGE_CONTENT.DROP_DOWN.STEPS.map((step, index) => (
              <li key={index}>{step}</li>
            ))}
          </ol>
        </Accordion>
      </main>
    </FormContainer>
  );
};

export const OfficialLoginForm = () => {
  const { state } = useLocation();
  const navigate = useNavigateState();
  const cookies = getCookies();
  const { setShowLoginForm } = useTeamContext();

  const savedFields =
    !cookies.encrypted_fields ||
    JSON.parse(appCrypto.decrypt(cookies.encrypted_fields) || "");

  const initialValues: OfficialLoginFormType = {
    email: savedFields.email || "",
    password: savedFields.password || "",
    rememberMe: savedFields.rememberMe || false,
    generalErr: "",
  };

  const submitForm = async (
    values: OfficialLoginFormType & { generalErr: string },
    {
      setSubmitting,
      setErrors,
    }: {
      setSubmitting: (isSubmitting: boolean) => void;
      setErrors: (errors: Record<string, string>) => void;
    }
  ) => {
    try {
      setSubmitting(true);
      const { generalErr: _, ...fplAuth } = values;
      await loginToOfficialFPl(fplAuth);
      TrackGoogleAnalyticsEvent(GA4_EVENTS.OFFICIAL_SIGN_IN);
      setShowLoginForm(false);
      const redirectUrl =
        (state && state.page) ||
        sessionStorage.getItem(SESSION_STORAGE.URL_HISTORY);

      if (redirectUrl) {
        navigate(redirectUrl, {
          replace: true,
        });
      }
    } catch (e) {
      if (isAxiosError(e)) {
        setErrors({ generalErr: e.response?.data.error });
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={SignInYupSchema}
      onSubmit={submitForm}
    >
      {({ isSubmitting }) => (
        <Form css={formSubmitStyles(isSubmitting)}>
          <Input
            Icon={<img src={message} />}
            placeholder={OFFICIAL_LOGIN_PAGE_CONTENT.EMAIL_FIELD_PLACEHOLDER}
            id="email"
            name="email"
            autoComplete="off"
          />
          <Input
            Icon={<img src={password} />}
            placeholder={OFFICIAL_LOGIN_PAGE_CONTENT.PASSWORD_FIELD_PLACEHOLDER}
            type="password"
            id="password"
            name="password"
          />
          <div css={rememberMeAndForgotPass}>
            <Checkbox name="rememberMe">
              {OFFICIAL_LOGIN_PAGE_CONTENT.REMEMBER_ME}
            </Checkbox>
          </div>
          <ErrorMessage name="generalErr" />
          <Button button="CTA" type="submit" disabled={isSubmitting}>
            {SIGN_IN_PAGE_CONTENT.CTA_BUTTON_TEXT}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

const rememberMeAndForgotPass = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const extraStyles = css`
  border-radius: 1rem;
  min-height: fit-content;
`;

const root = css`
  ${container}
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  ${mq["md"]} {
    margin-top: 0;
  }
`;

const heading = css`
  text-align: center;
  width: 100%;
  h1 {
    font-size: 2.375rem;
    color: ${secondaryColor};
    margin-bottom: 1rem;
    font-weight: 700;
  }
  p {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
  }
`;

const stepsStyles = css`
  margin: 0;
  li {
    list-style-type: decimal;
  }
`;
