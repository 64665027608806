import { css } from "@emotion/react";
import { Stack } from "@mui/material";
import { getDifficultyColor } from "../../../utils/general.helpers";

interface IFixtureDifficulty {
  fixtureDifficulty: { difficulty: number; op_team_name: string }[];
}

export const FixtureDifficulty = (props: IFixtureDifficulty) => {
  return (
    <Stack css={rootStyles}>
      {props.fixtureDifficulty.map((difficulty, index) => (
        <span
          key={index}
          style={{
            color: getDifficultyColor(difficulty?.difficulty).textColor,
            backgroundColor: getDifficultyColor(difficulty?.difficulty).bgColor,
          }}
        >
          {difficulty?.op_team_name}
        </span>
      ))}
    </Stack>
  );
};

const rootStyles = css`
  flex-direction: row;
  width: 100%;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    font-size: clamp(0.5rem, 1vw, 0.75rem);
    font-weight: 600;
    text-align: center;
    border-right: 1px solid white;

    :last-of-type {
      border-right: none;
    }
  }
`;
