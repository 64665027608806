import { AppTabs } from "../../../components";
import { secondaryColor } from "../../../config/theme";
import { TEAMS_PAGE_TABS } from "../../../constants/view-team-pages.constants";
import { useGlobalInfoContext } from "../../../context/GlobalInfoContext";
import { useSnackbarContext } from "../../../context/SnackbarContext";
import { teamTabsType, useTeamContext } from "../../../context/TeamContext";

export const MakeTransfersTabs = () => {
  const { setSnackBarState } = useSnackbarContext();
  const { selectedLeague } = useGlobalInfoContext();
  const { selectedTab, setSelectedTab } = useTeamContext();
  const handleTabsChange = (_: any, tab: teamTabsType) => {
    if (tab === TEAMS_PAGE_TABS.OWNERSHIP) {
      setSnackBarState((prev) => ({
        ...prev,
        isOpen: true,
        message: `You’re currently seeing the ownership of your picks within the ${selectedLeague?.name} league`,
        duration: 5000,
      }));
    }
    setSelectedTab(tab);
  };

  return (
    <AppTabs
      selectedColor={secondaryColor}
      handleTabsChange={handleTabsChange}
      value={selectedTab}
      tabs={Object.values(TEAMS_PAGE_TABS).map((tab) => ({
        label: tab,
        value: tab,
      }))}
    />
  );
};
