import { Box, Stack } from "@mui/material";
import { css } from "@emotion/react";
import {
  FootballField,
  FootballFieldSkeleton,
  Player,
} from "../../../components";
import midField from "../../../assets/view-team/mid-field.svg";
import { transition_all } from "../../../config/styles";
import { useTeamContext } from "../../../context/TeamContext";
import { ITransfersTeamInfo } from "../../../utils/hooks/useGetTransfersTeamInfo";
import { TEAMS_PAGE_TABS } from "../../../constants/view-team-pages.constants";
import { useGetFplTeamFieldTabsData } from "../../../utils/hooks/useGetFplTeamFieldTabsData";

export const PickTeamFormation = () => {
  const {
    pickTeam,
    pickTeamPositionedPlayers,
    switchPlayers,
    selectedTab,
    setSwitchPlayers,
    openEplPlayerModal,
  } = useTeamContext();
  const { fplTeamFieldTabsData } = useGetFplTeamFieldTabsData();
  const teamFieldTabsData = fplTeamFieldTabsData?.fplTeamFieldTabsData;
  const leagueSize = fplTeamFieldTabsData?.leagueSize;

  const handlePLayerClick = (eplPlayer: ITransfersTeamInfo["picks"][0]) => {
    if (
      switchPlayers.playerIn === eplPlayer.position ||
      switchPlayers.playerOut === eplPlayer.position
    ) {
      setSwitchPlayers({
        playerIn: null,
        playerOut: null,
        availablePlayers: [],
      });
    } else if (switchPlayers.playerIn || switchPlayers.playerOut) {
      setSwitchPlayers((prev) => ({
        ...prev,
        playerIn: switchPlayers.playerIn || eplPlayer.position,
        playerOut: switchPlayers.playerOut || eplPlayer.position,
      }));
    } else {
      openEplPlayerModal(eplPlayer.element.toString());
    }
  };

  if (!pickTeam) return <FootballFieldSkeleton />;

  return (
    <FootballField activeChips={pickTeam.activeChip}>
      {Object.entries(pickTeamPositionedPlayers).map(
        ([position, playersType]) => (
          <Stack
            component="li"
            key={position}
            css={playerRowStyle(pickTeam.activeChip === "bboost")}
          >
            {position === "SUBS" && <img src={midField} alt="mid Field" />}
            {playersType.map((player) => (
              <Box
                key={player.element}
                css={[
                  playerContainerStyles(
                    player.position === switchPlayers.playerIn
                      ? "playerIn"
                      : player.position === switchPlayers.playerOut
                      ? "playerOut"
                      : ""
                  ),
                  !switchPlayers.availablePlayers.includes(player.element) &&
                    (switchPlayers.playerIn || switchPlayers.playerOut) &&
                    css`
                      opacity: 0.5;
                      pointer-events: none;
                    `,
                ]}
                onClick={() => handlePLayerClick(player)}
              >
                <Player
                  isCaptain={player.is_captain}
                  isViceCaptain={player.is_vice_captain}
                  isTripleCapChipPlayed={pickTeam.activeChip === "3xc"}
                  ownerShip={
                    selectedTab === TEAMS_PAGE_TABS.OWNERSHIP &&
                    teamFieldTabsData &&
                    `${
                      teamFieldTabsData[player.element].ownership
                    } / ${leagueSize}`
                  }
                  fixturesDifficulty={
                    selectedTab === TEAMS_PAGE_TABS.UPCOMING &&
                    teamFieldTabsData &&
                    teamFieldTabsData[player.element].upcomingFixtures
                  }
                  history={
                    selectedTab === TEAMS_PAGE_TABS.HISTORY &&
                    teamFieldTabsData &&
                    teamFieldTabsData[player.element].pointsHistory
                  }
                  value={{
                    sellingPrice: player.selling_price,
                    purchasePrice: player.purchase_price,
                  }}
                  name={player.name}
                  fieldPosition={player.field_position}
                  playerShirt={
                    player.field_position === "GK"
                      ? player.gk_shirt_link
                      : player.shirt_link
                  }
                />
              </Box>
            ))}
          </Stack>
        )
      )}
    </FootballField>
  );
};

const playerRowStyle = (isbboost: boolean) => css`
  flex: 1;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding-block: clamp(0.7rem, 1vw, 1.5rem);

  &:last-of-type {
    position: relative;
    padding-block: clamp(1.2rem, 2.5vw, 3rem);
    background-color: #f0f8ff7e;

    ${isbboost &&
    css`
      border-radius: 0.25rem;
      box-shadow: 0 0 0.25rem clamp(5px, 2vw, 7px) white inset;
    `}

    & > img:first-of-type {
      position: absolute;
      z-index: -1;
      top: 0;
      translate: 0 -50%;
      width: 100%;
    }

    & > *:first-of-type {
      margin-inline: 0.5rem 1.5rem;
    }
  }
`;

const playerContainerStyles = (
  playerStats: "playerIn" | "playerOut" | ""
) => css`
  ${transition_all}
  cursor: pointer;
  border-radius: 0.5rem;

  --shadow-x: clamp(10px, 3vw, 15px);
  --shadow-y: clamp(5px, 2vw, 10px);

  ${playerStats === "playerIn" &&
  css`
    box-shadow: 0 0 0 clamp(5px, 2vw, 7px) #0be65b;
    background-color: #0be65b;
  `}

  ${playerStats === "playerOut" &&
  css`
    box-shadow: 0 0 0 clamp(5px, 2vw, 7px) #ff0245;
    background-color: #ff0245;
  `}
`;
