import chart from "../assets/cool-feature/chart.svg";
import mostHurtful from "../assets/cool-feature/most-hurtful.svg";
import tshirt from "../assets/cool-feature/tshirt.svg";

export const INTRO_PAGE_CONTENT = {
  META_DATA: {
    TITLE: "Intro",
  },
  TITLE: "Discover all about your FPL mini league",
  REGISTER_OR_LOGIN_BUTTON: "Sign Up / Login",
  SIGN_IN_BUTTON: "Sign In",
  VISITOR_MODE_BTN: "Continue as guest",
  FEATURES: [
    {
      title: "Explore FPL highlights",
      img: chart,
      description:
        "20+ exciting mini-league highlights categorized into amusing, stinky and basic highlights for an edge in your fantasy football journey.",
    },
    {
      title: "Fixtures Player Ownership",
      img: tshirt,
      description:
        "Check which manager owns/captains which players in all fixtures.",
    },
    {
      title: "Most Hurtful Players",
      img: mostHurtful,
      description:
        "Reveals league’s top scorers in your mini-league that others own but you don't!",
    },
  ],
};

export const REGISTER_PAGE_CONTENT = {
  META: {
    TITLE: "Register",
  },
  TITLE: "Register",
  SUBTITLE:
    "Register to get the latest FPL highlights and share the joy with your FPL friends",
  CTA_BUTTON_TEXT: "Create Account",
  GOOGLE_BUTTON_TEXT: "Sign Up With Google",
  NAME_FIELD_PLACEHOLDER: "Name",
  EMAIL_FIELD_PLACEHOLDER: "Email",
  PASSWORD_FIELD_PLACEHOLDER: "Password",
  CONFIRM_PASSWORD_PLACEHOLDER: "Confirm password",
  CHECKBOX: {
    TERMS_OF_SERVICE: "Terms of service",
    PRIVACY_POLICY: "Privacy Policy",
    TEXT: `I agree to The and`,
  },
  HAVE_ACCOUNT: {
    TEXT: "Do you have account?",
    CTA: "Sign In",
  },
  GUEST_BTN: "Continue as guest",
};

export const TEAM_DETAIL_PAGE = {
  META: {
    TITLE: "Team Id",
  },
  TITLE: "Let's find your FPL team.",
  SUBTITLE:
    "Link your FPL team to get your mini-leagues highlights every game week.",
  CTA_BUTTON_TEXT: "Submit",
  TEAM_MANE_FIELD_PLACEHOLDER: "Search by Team or Manager name",
  TEAM_NAME_NOT_FOUND: "Team not found",
  TEAM_ID_FIELD_SUBTITLE: "Or link it by your team ID",
  TEAM_ID_FIELD_PLACEHOLDER: "Enter your team ID",
  FIND_TEAM_ID: {
    TITLE: "Don’t know your team ID?",
    STEPS: {
      ONE: {
        TEXT: "Head to",
        LINK: "https://fantasy.premierleague.com",
        LINK_TEXT: "fantasy.premierleague.com",
      },
      TWO: "Login to your FPL account and navigate to the points page/gameweek history page.",
      THREE:
        "The URL will have a unique number between /entry/ and /event/ which is your FPL team ID", //Make the word "only" bold
      FOUR: {
        FIRST_PART: "Note: You need to do this",
        ONLY: "only",
        LAST_PART: "via a browser on a mobile/tablet/laptop/desktop.",
      },
    },
  },

  FIND_TEAM_NAME: {
    TITLE: "Don’t know your team name?",
    GO_TO_FPL: {
      TEXT: "Head to",
      LINK: "https://fantasy.premierleague.com",
      LINK_TEXT: "fantasy.premierleague.com",
    },
    STEPS: [
      "Click on the Fantasy tab.",
      "You will find your team name at the top as shown in the image:",
    ],
  },

  CONFIRM_TEAM: {
    TITLE: "Select your team",
    SUBTITLE: "Are you sure this is your team?",
    TEAM_NAME: "Team Name",
    TEAM_ID: "Team Id",
    MANAGER_NAME: "Manager Name",
    OVERALL_RANK: "Over all rank",
  },

  TEAM_NOT_FOUND: {
    TITLE: "Didn’t find your team?",
    BTN: "Enter team ID",
  },

  SECOND_TIME: {
    TITLE: "Second time here?",
    SUBTITLE: "Save your time and signup for easier access to your team",
    BTN: "Signup / Login",
  },

  SHOW_MORE: {
    TITLE: "Show more",
  },
};

export const SIGN_IN_PAGE_CONTENT = {
  META: {
    TITLE: "Sign In",
  },
  TITLE: {
    PRE_HEADING: "Welcome to",
    HEADING: "FPL Champ!",
  },
  SUBTITLE: "Login",
  CTA_BUTTON_TEXT: "Sign In",
  GOOGLE_BUTTON_TEXT: "Continue with google",
  EMAIL_FIELD_PLACEHOLDER: "Email",
  PASSWORD_FIELD_PLACEHOLDER: "Password",
  CHECKBOX_TEXT: "Remember me",
  FORGET_PASSWORD: "Forgot Password?",
  HAVE_ACCOUNT: {
    TEXT: "Don’t have account?",
    CTA: "Sign up",
  },
};

export const RESET_PASSWORD_PAGE = {
  META: {
    TITLE: "Reset Password",
  },
  TITLE: "Reset Password",
  SUBTITLE: "Congrats! Now insert a password that you will not forget again!",
  CTA_BUTTON_TEXT: "Submit",
  PASSWORD_FIELD_PLACEHOLDER: "Password",
  CONFIRM_PASSWORD_FIELD_PLACEHOLDER: "Confirm Password",
};

export const FORGOT_PASSWORD_PAGE = {
  META: {
    TITLE: "Forget Password",
  },
  TITLE: "Forget Password",
  SUBTITLE:
    "Forgot it? no worries it happened to all of us, Just provide us with the following",
  CTA_BUTTON_TEXT: "Continue",
  RESEND_BUTTON_TEXT: "Resend",
  NAME_FIELD_PLACEHOLDER: "Email",
};

export const AUTH_ERROR_MESSAGES = {
  NAME: {
    REQUIRED: "Name is required.",
  },
  EMAIL: {
    INVALID_FORMAT: "Invalid email format.",
    REQUIRED: "Email is required.",
    ALREADY_EXIST: "Email already exist",
  },
  PASSWORD: {
    MIN_LENGTH: "Password must be at least 8 characters.",
    MAX_LENGTH: "Password must be at most 72 characters.",
    COMPLEX_PASSWORD:
      "Password must contain at least one lowercase letter, one uppercase letter, one number, and one special character",
    REQUIRED: "Password is required.",
  },
  CONFIRM_PASSWORD: {
    NOT_MATCH: "Passwords must match",
    REQUIRED: "Confirm password is required.",
  },
  POLICY: { CHECKED: "Must agree to terms of service" },
  TEAM_ID: {
    NUMBER: "Team Id must be a number",
    REQUIRED: "Team ID is required.",
  },
  JWT: {
    EMPTY_AUTHORIZATION_HEADER:
      "Authentication failed: No JWT provided in the Authorization header",
  },
};

export const GET_STARTED_PAGES = {
  META: {
    TITLE: "Get Started",
  },
  TITLE: "Signup to gain access to",
  SUBTITLE: "+ Many more features",
  EMOJI: "🔥",
  BTN: "Get Started",
};

export const OFFICIAL_LOGIN_PAGE_CONTENT = {
  META: {
    TITLE: "Link FPL",
  },
  TITLE: "Link FPL",
  SUBTITLE:
    "Link your FPL team to do your transfers easily right from the website.",
  EMAIL_FIELD_PLACEHOLDER: "FPL email address",
  PASSWORD_FIELD_PLACEHOLDER: "Password",
  REMEMBER_ME: "Remember me",
  DROP_DOWN: {
    TITLE: "How your FPL data is handled",
    STEPS: [
      "We do NOT store your FPL credentials on our servers",
      "They are solely used to make the transfers",
      "If you choose the “Remember me” option, they will be saved on your browser and NOT our servers.",
    ],
  },
};
