import { useEffect, useState } from "react";
import {
  TeamFormation,
  TeamBanner,
  TeamBannerSkeleton,
  EplPlayerSummary,
  TeamPagesHeader,
  MakeTransfersTabs,
} from "./components";
import { Box, Stack, css } from "@mui/material";
import { useParams, useSearchParams } from "react-router-dom";
import { useUserContext } from "../../context/UserContext";
import {
  FootballFieldSkeleton,
  OfficialLoginSection,
  SelectGameWeekDropDown,
  TeamNavigationTabs,
} from "../../components";
import { useGetUrl } from "../../utils/hooks/useGetUrl";
import { PATH_PARAMS, QUERY_PARAMS } from "../../config/routes";
import { useNavigateState } from "../../utils/hooks/useNavigateState";
import { Helmet } from "react-helmet";
import { ITeamInfo, useGetTeamInfo } from "../../utils/hooks/useGetTeamInfo";
import { useTeamContext } from "../../context/TeamContext";

/**
 * Main function that renders the View Team Page
 */

export const ViewTeamPage = () => {
  const urlParams = useParams();
  const { user } = useUserContext();
  const { teamInfo: teamData } = useGetTeamInfo({
    teamId: urlParams[PATH_PARAMS.TEAM_ID]!,
  });
  const [playerSummary, setPlayerSummary] = useState<
    ITeamInfo["picks"][0] | null
  >(null);
  const { getTeamViewPath } = useGetUrl();
  const navigate = useNavigateState();
  const [searchParams, setSearchParams] = useSearchParams();
  const { showLoginForm } = useTeamContext();

  useEffect(() => {
    if (!urlParams[PATH_PARAMS.TEAM_ID] && user) {
      navigate(getTeamViewPath(user.teamId!), { replace: true });
    }
  }, [urlParams, user]);

  useEffect(() => {
    if (searchParams.get(QUERY_PARAMS.EPL_PLAYER_INFO_MODAL) && teamData) {
      setPlayerSummary(
        teamData.picks.find(
          (player) =>
            player.element.toString() ===
            searchParams.get(QUERY_PARAMS.EPL_PLAYER_INFO_MODAL)
        )!
      );
    } else {
      setPlayerSummary(null);
    }
  }, [searchParams, teamData]);

  const handlePlayerClick = (eplPlayer: ITeamInfo["picks"][0]) => {
    searchParams.set(
      QUERY_PARAMS.EPL_PLAYER_INFO_MODAL,
      eplPlayer.element.toString()
    );
    setSearchParams(searchParams);
  };

  const closeEplPlayerSummary = () => {
    searchParams.delete(QUERY_PARAMS.EPL_PLAYER_INFO_MODAL);
    setSearchParams(searchParams);
  };

  return (
    <Box css={rootContainerStyles}>
      <Helmet>
        <title>{`Team - ${teamData ? teamData.name : ""}`}</title>
      </Helmet>

      {Boolean(playerSummary) && (
        <EplPlayerSummary
          open={Boolean(playerSummary)}
          onClose={closeEplPlayerSummary}
          eplPlayer={playerSummary}
        />
      )}

      <Stack css={rootStyle}>
        <Stack css={titleSectionStyles}>
          <TeamPagesHeader teamData={teamData} />
          <SelectGameWeekDropDown
            lowestValue={teamData && teamData.started_event}
          />
          <span css={dividerStyles} />
        </Stack>

        <Box css={bannerContainerStyle}>
          {teamData != null ? (
            <TeamBanner
              entryHistory={teamData.entry_history}
              numberOfPlayers={teamData.number_of_players}
            />
          ) : (
            <TeamBannerSkeleton />
          )}
        </Box>

        <TeamNavigationTabs />

        {showLoginForm && user?.teamId === urlParams[PATH_PARAMS.TEAM_ID] && (
          <OfficialLoginSection />
        )}

        {!showLoginForm &&
          (teamData ? (
            <>
              <MakeTransfersTabs />
              <TeamFormation
                eplPlayerClick={handlePlayerClick}
                isTripleCapChipPlayed={Boolean(
                  teamData.active_chip && teamData.active_chip === "3xc"
                )}
                activeChip={teamData.active_chip}
                teamPlayers={teamData.picks}
              />
            </>
          ) : (
            <FootballFieldSkeleton />
          ))}
      </Stack>
    </Box>
  );
};

const rootContainerStyles = css`
  max-width: 50rem;
  margin-inline: auto;
  gap: 1rem;
`;

const rootStyle = css`
  gap: 1rem;
`;

const bannerContainerStyle = css`
  width: 100%;
  max-width: clamp(27rem, 45vw, 40rem);
  margin-inline: auto;
`;

const dividerStyles = css`
  display: block;
  width: 100%;
  height: 1px;
  margin-inline: auto;
  background: #838383;
  border-radius: 9999px;
`;

const titleSectionStyles = css`
  gap: 1rem;
`;
