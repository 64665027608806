import { css } from "@emotion/react";
import { Typography } from "@mui/material";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { Skeleton } from "./Skeleton";
import { FIXTURE_PAGE } from "../constants/fixtures-pages.constants";
import { getDeadLineDate } from "../utils/dateHandlers";
import { useGlobalInfoContext } from "../context/GlobalInfoContext";
import { secondaryColor } from "../config/theme";
import { PATH_PARAMS, QUERY_PARAMS } from "../config/routes";
import { useSearchParams } from "react-router-dom";
import { textWrapBalance } from "../config/styles";

export const TransfersDeadline = ({
  useFutureGameWeek,
  useNextGameWeek,
}: {
  useFutureGameWeek?: boolean;
  useNextGameWeek?: boolean;
}) => {
  const { allInfo } = useGlobalInfoContext();
  const [searchParams] = useSearchParams();

  const gameWeekParam = useFutureGameWeek
    ? QUERY_PARAMS.FUTURE_GAME_WEEK
    : PATH_PARAMS.SELECTED_GAME_WEEK;

  const baseGameWeek = Number(searchParams.get(gameWeekParam));
  const gw =
    useFutureGameWeek || !useNextGameWeek ? baseGameWeek : baseGameWeek + 1;

  const currentGameWeek = allInfo?.events.find((item) => item.id === gw);

  return (
    <Typography css={deadlineStyles}>
      <NotificationsNoneIcon />
      {currentGameWeek ? (
        `${
          FIXTURE_PAGE.FIXTURE_DEADLINE_TITLE
        } ${`for GW${gw}`}: ${getDeadLineDate(currentGameWeek.deadline_time)}`
      ) : (
        <Skeleton />
      )}
    </Typography>
  );
};

const deadlineStyles = css`
  ${textWrapBalance}
  font-size: clamp(1rem, 2vw, 1.5rem);
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 0.3rem;

  svg {
    color: ${secondaryColor};
  }
`;
