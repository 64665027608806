import React, { ReactNode } from "react";
import Rollbar from "rollbar";
import { Context, getRollbarFromContext } from "@rollbar/react";
import { ENV_OPTIONS, SESSION_STORAGE } from "../config/app.config";
import { isAxiosError } from "axios";
import { ErrorBoundaryFallbackUI } from "./ErrorBoundaryFallbackUI";

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}
/**
 * ErrorBoundary component that wraps the entire app and logs data to rollbar
 */
export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  static contextType = Context;
  rollbar: Rollbar | undefined;
  declare context: typeof Context;

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  /** Reset State */
  resetState = () => {
    return this.setState({ hasError: false });
  };

  /** gets rollbar from context */
  componentDidMount() {
    this.rollbar = getRollbarFromContext(this.context);
  }

  /** update state if there is an error */
  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }

  /** logs all caught  errors to rollbar */
  componentDidCatch(error: Error, info: React.ErrorInfo) {
    const user = sessionStorage.getItem(SESSION_STORAGE.ROLLBAR_USER)
      ? JSON.parse(sessionStorage.getItem(SESSION_STORAGE.ROLLBAR_USER)!)
      : null;

    let logError: { [key: string]: any } = {};

    if (isAxiosError(error)) {
      logError.name = error.message;
      logError.message = error.response?.data.error.details.response;
      logError.endPoint = error.response?.data.error.details.url;
      logError.status = error.status;
      logError.statusText = error.response?.statusText;
    } else {
      logError = error;
    }

    if (
      this.rollbar &&
      import.meta.env.VITE_ENVIRONMENT !== ENV_OPTIONS.LOCAL
    ) {
      const errName = error.message || error.name;
      this.rollbar.error(errName, {
        fullPath: window.location.href,
        pathname: window.location.pathname,
        searchParams: window.location.search,
        logError,
        errorMessage: error.message,
        errorName: error.name,
        errorInfo: error.stack,
        componentStack: info.componentStack,
        ...user,
      });
    }
  }

  render() {
    if (this.state.hasError) {
      return <ErrorBoundaryFallbackUI resetState={this.resetState} />;
    }
    return this.props.children;
  }
}
