import React from "react";

export const MagicWand = ({
  fill = "#8E8E8E",
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.75 12V16.5"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5 14.25H23"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.375 3.75V7.5"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 5.625H10.25"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.25 17.25V20.25"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.75 18.75H17.75"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.9697 3.53033L4.03033 17.4697C3.73744 17.7626 3.73744 18.2374 4.03033 18.5303L5.96967 20.4697C6.26256 20.7626 6.73744 20.7626 7.03033 20.4697L20.9697 6.53033C21.2626 6.23744 21.2626 5.76256 20.9697 5.46967L19.0303 3.53033C18.7374 3.23744 18.2626 3.23744 17.9697 3.53033Z"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 7.5L17 10.5"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
