import { Box, Stack } from "@mui/material";
import { css } from "@emotion/react";
import React from "react";
import { mq, primaryGradient, shadow } from "../config/styles";
import {
  bgColor,
  primaryColor,
  secondaryBgColor,
  secondaryColor,
} from "../config/theme";
import { Skeleton } from "./Skeleton";
import playerShirt from "../assets/view-team/player-shirt.svg";
import priceTag from "../assets/make-transfers/price-tag.svg";
import check from "../assets/cool-feature/check.svg";
import { FixtureDifficulty } from "../pages/view-team-pages/components";
import { ChangeIndicator } from ".";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

interface IPlayer {
  name: string;
  points?: string | number;
  value?: {
    sellingPrice: number;
    purchasePrice: number;
  };
  playerShirt: string;
  fieldPosition: string;
  children?: React.ReactNode;
  opTeamName?: string;
  fixturesDifficulty?: { difficulty: number; op_team_name: string }[] | false;

  ownerShip?: number | string | false;
  history?: number[] | false;
  isCaptain?: boolean;
  isViceCaptain?: boolean;
  isTripleCapChipPlayed?: boolean;
}

const getPointsColor = (points: number) => {
  if (points <= 2) return "red";
  if (points <= 6) return "orange";
  if (points <= 9) return secondaryColor;
  return "green";
};

/**
 * Component that renders the player shirt with the a name field below the shirt
 * and a floating circle that display the points
 */
export const Player = (props: IPlayer) => {
  return (
    <Stack css={playerContainerStyle}>
      <img src={props.playerShirt} />

      {props.points !== undefined && (
        <span css={playerPoints}>{props.points}</span>
      )}

      {props.value !== undefined && (
        <Stack css={playerValueContainer}>
          <Stack css={rankIndicatorContainer}>
            <ChangeIndicator
              Icon={KeyboardArrowDownRoundedIcon}
              prevValue={props.value.purchasePrice}
              currentValue={props.value.sellingPrice}
            />
          </Stack>
          <span css={playerValue}>{props.value.sellingPrice / 10}£</span>
          <img css={priceTagStyles} src={priceTag} />
        </Stack>
      )}

      {props.isCaptain && (
        <span css={captainBadgeStyle(!!props.isTripleCapChipPlayed)}>C</span>
      )}
      {props.isViceCaptain && <span css={viceCaptainBadgeStyle}>V</span>}

      <Box css={playerInfoStyle}>
        <p>{props.name}</p>
        {/* {props.opTeamName && <p>{props.opTeamName}</p>} */}

        {props.fixturesDifficulty && (
          <FixtureDifficulty fixtureDifficulty={props.fixturesDifficulty} />
        )}

        {props.ownerShip && <PlayerOwnership ownerShip={props.ownerShip} />}

        {props.history && <PlayerHistory history={props.history} />}
      </Box>

      {props.children}
    </Stack>
  );
};

export const PlayerSkelton = () => {
  return (
    <Stack css={playerContainerStyle}>
      <img src={playerShirt} />
      <span css={[playerPoints, skeletonPlayerPoints]}></span>
      <Skeleton css={[playerInfoStyle, skeletonPlayerInfoStyle]} />
    </Stack>
  );
};

export const PlayerOwnership = (props: {
  ownerShip: string | number;
  bgColor?: string;
}) => {
  return (
    <Stack css={ownerShipStyles(props.bgColor)}>
      <img src={check} />
      {props.ownerShip}
    </Stack>
  );
};

export const PlayerHistory = (props: { history: number[] }) => {
  return (
    <Stack
      css={css`
        flex-direction: row;

        span {
          flex: 1;
          text-align: center;
          border-right: 1px solid white;
          display: flex;
          align-items: center;
          justify-content: center;

          :last-of-type {
            border-right: none;
          }
        }
      `}
    >
      {props.history.map((points, index) => (
        <span
          key={index}
          style={{
            backgroundColor: getPointsColor(points),
          }}
        >
          {points}
        </span>
      ))}
    </Stack>
  );
};

const ownerShipStyles = (bgColor?: string) => css`
  white-space: nowrap;
  padding: 0.2rem;
  background: ${bgColor || secondaryBgColor};
  min-width: 100%;
  flex-direction: row;
  text-align: center;
  align-items: center;
  justify-content: center;
  line-height: 1;
  gap: 0.3rem;
  img {
    width: 15px;
  }
`;

const playerContainerStyle = css`
  position: relative;
  justify-content: center;
  align-items: center;

  img {
    max-width: clamp(3.5rem, 9vw, 6rem);
    height: clamp(3.5rem, 9vw, 6rem);
  }

  ${mq["md"]} {
    img {
      max-width: clamp(2.5rem, 8vw, 5rem);
    }
  }
`;

const playerInfoStyle = css`
  background-color: white;
  width: clamp(4rem, 15vw, 8rem);
  border-radius: 0.6rem;
  overflow: hidden;
  ${shadow}

  P {
    margin: 0;
    font-weight: 600;
    color: black;
    text-align: center;
  }

  & > p:nth-of-type(1) {
    padding-top: clamp(0.3rem, 1.1vw, 0.5rem);
    padding-bottom: clamp(0.2rem, 1vw, 0.4rem);
    padding-inline: 0.5rem;
    font-size: clamp(0.825rem, 1.5vw, 1.1rem);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & > p:nth-of-type(2) {
    display: inline-block;
    font-size: clamp(0.75rem, 2vw, 0.875rem);
    padding-block: clamp(0.1rem, 0.5vw, 0.2rem);
    background-color: rgba(226, 66, 44, 0.6);
    width: 100%;
    text-align: center;
  }

  & > div:last-of-type {
    height: 1.25rem;
  }

  ${mq["md"]} {
    width: clamp(3rem, 10vw, 7rem);
  }
`;

const playerPoints = css`
  --width: clamp(1.7rem, 3vw, 2.1rem);
  position: absolute;
  top: clamp(2.5rem, 6.5vw, 3.4rem);
  left: 50%;
  translate: -50% 0;

  display: inline-block;
  width: var(--width);
  line-height: var(--width);
  aspect-ratio: 1/1;
  background-color: ${primaryColor};
  border-radius: 99999px;
  box-shadow: 0 9.41px 9.41px 0 rgba(0, 0, 0, 0.25);
  font-size: clamp(0.65rem, 1.9vw, 1.1rem);
  font-weight: 600;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const skeletonPlayerInfoStyle = css`
  background: #1c1c2d;
`;

const skeletonPlayerPoints = css`
  background-color: ${bgColor};
  color: #3b3b63;
  z-index: 1000;
`;

const playerValueContainer = css`
  ${primaryGradient}

  --width: clamp(1.7rem, 3vw, 2.1rem);
  position: absolute;
  top: clamp(2.4rem, 6.5vw, 4.4rem);
  left: 50%;
  translate: -50% 0;
  padding: 0.15rem 0.5rem;
  width: fit-content;
  border-radius: 0.5rem;

  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${mq["md"]} {
    padding: 0.3rem 0.7rem;
  }
`;

const rankIndicatorContainer = css`
  margin-right: 0.2rem;
  aspect-ratio: 1/1;
  border-radius: 999px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const playerValue = css`
  font-size: clamp(0.8rem, 1.5vw, 1rem);
  font-weight: 700;
  text-align: center;
  color: black;
`;

const priceTagStyles = css`
  position: absolute;
  top: 0;
  left: 0;
  translate: -70% -50%;
  width: 30px;
`;

const badgeStyle = css`
  --width: clamp(1.2rem, 3vw, 2rem);
  display: block;
  position: absolute;
  right: -0.25rem;
  top: -0.25rem;
  width: var(--width);
  line-height: var(--width);
  text-align: center;
  aspect-ratio: 1/1;
  border-radius: 99999px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: clamp(0.75rem, 2vw, 1.12rem);
  font-weight: 600;
  color: ${bgColor};
`;

const captainBadgeStyle = (isTripleCapChipPlayed: boolean) => css`
  ${badgeStyle}
  background: linear-gradient(to bottom right,#FFF886, #F072B6);
  ${isTripleCapChipPlayed &&
  css`
    background: white;
    &::after {
      position: absolute;
      z-index: -1;
      top: -2px;
      left: -2px;
      content: "";
      display: block;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      ${primaryGradient}
      border-radius: 999px;
    }
  `}
`;

const viceCaptainBadgeStyle = css`
  ${badgeStyle}
  ${primaryGradient};
`;
