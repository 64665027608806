import { Box } from "@mui/material";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { AppTabsBar } from "../../../components";
import { QUERY_PARAMS } from "../../../config/routes";
import {
  EPL_PLAYER_SUMMARY,
  tabsConstants,
} from "../../../constants/view-team-pages.constants";
import { useEplPlayerSummary } from "../../../utils/hooks/useEplPlayerSummary";
import { EplPlayerSummaryFixtureTable } from "./EplPlayerSummaryFixtureTable";
import { EplPlayerSummaryHistoryTable } from "./EplPlayerSummaryHistoryTable";
import { EplPlayerSummaryOwnershipTable } from "./EplPlayerSummaryOwnershipTable";
import { EplPlayerSummaryStats } from "./EplPlayerSummaryStats";
import { css } from "@emotion/react";

export const EplPlayerStats = () => {
  const [selectedTab, setSelectedTab] = useState(
    EPL_PLAYER_SUMMARY.TABS[0].value
  );
  const { eplPlayersSummary } = useEplPlayerSummary();

  const [searchParams] = useSearchParams();

  const selectedEplPlayerSummary = eplPlayersSummary?.find(
    (i) =>
      i.element.toString() ===
      searchParams.get(QUERY_PARAMS.EPL_PLAYER_INFO_MODAL)
  );

  const handleTabsChange = (_: any, value: string) => {
    setSelectedTab(value);
  };
  return (
    <>
      <EplPlayerSummaryStats info={selectedEplPlayerSummary?.info} />

      <Box css={tabBarContainerStyles}>
        <AppTabsBar
          tabs={EPL_PLAYER_SUMMARY.TABS}
          value={selectedTab}
          onTabsChange={handleTabsChange}
        />
      </Box>

      <Box css={boxStyles}>
        {selectedTab === tabsConstants.history && (
          <EplPlayerSummaryHistoryTable
            historyRows={selectedEplPlayerSummary?.summary.history}
            historyPastRows={selectedEplPlayerSummary?.summary.history_past}
          />
        )}

        {selectedTab === tabsConstants.fixture && (
          <EplPlayerSummaryFixtureTable
            fixtureRows={selectedEplPlayerSummary?.summary.fixtures}
          />
        )}

        {selectedTab === tabsConstants.ownership &&
          selectedEplPlayerSummary && (
            <EplPlayerSummaryOwnershipTable
              eplPlayerSummary={selectedEplPlayerSummary}
            />
          )}
      </Box>
    </>
  );
};

const tabBarContainerStyles = css`
  box-shadow: 0 0 8px 0 #1b1b1b;
`;

const boxStyles = css``;
