import { MAKE_TRANSFERS_PAGE_CONTENT } from "../../../constants/view-team-pages.constants";
import { Button } from "../../../components";
import { Stack } from "@mui/material";
import { css } from "@emotion/react";
import { useGetUrl } from "../../../utils/hooks/useGetUrl";

export const EplPlayerTransferActions = () => {
  const { getTransferTablePath } = useGetUrl();

  return (
    <Stack css={transferActionsContainerStyles}>
      <Button button="gradient" to={getTransferTablePath()}>
        {MAKE_TRANSFERS_PAGE_CONTENT.MODAL_ACTIONS.TRANSFER}
      </Button>
    </Stack>
  );
};

const transferActionsContainerStyles = css`
  padding-inline: 1rem;
`;
