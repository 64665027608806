import { Stack, Box } from "@mui/material";
import {
  FootballField,
  Player,
  TeamFormationSkeleton,
} from "../../../components";
import { TEAMS_PAGE_TABS } from "../../../constants/view-team-pages.constants";
import { css } from "@emotion/react";
import { useState, useEffect } from "react";
import {
  ITransfersTeamInfo,
  useGetTransfersTeamInfo,
} from "../../../utils/hooks/useGetTransfersTeamInfo";
import { useTeamContext } from "../../../context/TeamContext";
import { arrangePlayerInTransfersTeam } from "../../../utils/arrangePlayersOnFootBallField";
import { useGetFplTeamFieldTabsData } from "../../../utils/hooks/useGetFplTeamFieldTabsData";
import { FILED_PLAYER_TYPES } from "../../../utils/types/types";

// interface IPositionedPlayers {
//   [key: FILED_PLAYER_TYPES]: ITransfersTeamInfo["picks"][0][];
// }

type IPositionedPlayers = Record<
  FILED_PLAYER_TYPES,
  ITransfersTeamInfo["picks"]
>;

export const MakeTransfersTeamFormation = () => {
  const { transfersTeamInfo } = useGetTransfersTeamInfo();
  const { fplTeamFieldTabsData } = useGetFplTeamFieldTabsData();
  const teamFieldTabsData = fplTeamFieldTabsData?.fplTeamFieldTabsData;
  const leagueSize = fplTeamFieldTabsData?.leagueSize;
  const { selectedTab, openEplPlayerModal } = useTeamContext();

  const [makeTransferPositionedPlayers, setMakeTransferPositionedPlayers] =
    useState<IPositionedPlayers>();

  useEffect(() => {
    if (!transfersTeamInfo) return;
    setMakeTransferPositionedPlayers(
      arrangePlayerInTransfersTeam(transfersTeamInfo.picks)
    );
  }, [transfersTeamInfo]);

  const handlePLayerClick = (eplPlayer: ITransfersTeamInfo["picks"][0]) => {
    openEplPlayerModal(eplPlayer.element.toString());
  };

  if (!makeTransferPositionedPlayers) {
    return <TeamFormationSkeleton />;
  }

  return (
    <FootballField showMidField activeChips={transfersTeamInfo?.activeChip!}>
      {Object.entries(makeTransferPositionedPlayers).map(
        ([position, playersType]) => (
          <Stack component={"li"} key={position} css={playerRowStyle}>
            {playersType.map((player, index) => (
              <Box
                key={index}
                css={playerContainerStyles}
                onClick={() => handlePLayerClick(player)}
              >
                <Player
                  ownerShip={
                    selectedTab === TEAMS_PAGE_TABS.OWNERSHIP &&
                    teamFieldTabsData &&
                    `${
                      teamFieldTabsData[player.element].ownership
                    } / ${leagueSize}`
                  }
                  fixturesDifficulty={
                    selectedTab === TEAMS_PAGE_TABS.UPCOMING &&
                    teamFieldTabsData &&
                    teamFieldTabsData[player.element].upcomingFixtures
                  }
                  history={
                    selectedTab === TEAMS_PAGE_TABS.HISTORY &&
                    teamFieldTabsData &&
                    teamFieldTabsData[player.element].pointsHistory
                  }
                  value={{
                    sellingPrice: player.selling_price,
                    purchasePrice: player.purchase_price,
                  }}
                  name={player.name}
                  fieldPosition={player.field_position}
                  playerShirt={
                    player.field_position === "GK"
                      ? player.gk_shirt_link
                      : player.shirt_link
                  }
                />
              </Box>
            ))}
          </Stack>
        )
      )}
    </FootballField>
  );
};

const playerRowStyle = css`
  flex: 1;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding-block: clamp(0.7rem, 1vw, 1.5rem);
`;

const playerContainerStyles = css`
  cursor: pointer;
`;
