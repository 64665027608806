import { axiosInstance } from "../../config/axios.config";
import { useQuery } from "react-query";
import { useParams, useSearchParams } from "react-router-dom";
import { PATH_PARAMS } from "../../config/routes";
import { FPL_CHAMP_ENDPOINTS } from "../../config/fplChampEndpoints.constants";

export type OwnershipData = {
  leagueSize: number;
  eplPlayersPicksCount: Record<string, number>;
};

export type UpcomingFixturesData = Record<
  string,
  {
    difficulty: number;
    op_team_name: string;
  }[]
>;

export type HistoryPointsData = Record<string, number[]>;

export const useGetFplTeamFieldTabsData = () => {
  const urlParams = useParams();
  const [searchParams] = useSearchParams();
  const selectedGameWeek = searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK);
  const queryKey = [
    "fpl-team-field-tabs-data",
    urlParams[PATH_PARAMS.LEAGUE_ID],
    urlParams[PATH_PARAMS.TEAM_ID],
    selectedGameWeek,
  ];

  const getFplTeamFieldTabsData = async () => {
    if (!queryKey.every((param) => param)) {
      return;
    }
    const [
      { data: ownershipData },
      { data: upcomingFixturesData },
      { data: historyPointsData },
    ] = await Promise.all([
      axiosInstance.get<OwnershipData>(
        FPL_CHAMP_ENDPOINTS.FPL_TEAM_PICKS_OWNERSHIP(
          urlParams[PATH_PARAMS.LEAGUE_ID]!,
          selectedGameWeek!
        )
      ),
      axiosInstance.get<UpcomingFixturesData>(
        FPL_CHAMP_ENDPOINTS.FPL_TEAM_PICKS_UPCOMING_FIXTURES(
          urlParams[PATH_PARAMS.TEAM_ID]!,
          selectedGameWeek!
        )
      ),
      axiosInstance.get<HistoryPointsData>(
        FPL_CHAMP_ENDPOINTS.FPL_TEAM_PICKS_HISTORY_POINTS(
          urlParams[PATH_PARAMS.TEAM_ID]!,
          selectedGameWeek!
        )
      ),
    ]);

    const fplTeamFieldTabsData: Record<
      string,
      {
        pointsHistory: number[];
        ownership: number;
        upcomingFixtures: UpcomingFixturesData[number];
      }
    > = {};

    for (const [eplPlayerId, pointsHistory] of Object.entries(
      historyPointsData
    )) {
      fplTeamFieldTabsData[eplPlayerId] = {
        pointsHistory: pointsHistory,
        ownership: ownershipData.eplPlayersPicksCount[eplPlayerId],
        upcomingFixtures: upcomingFixturesData[eplPlayerId],
      };
    }

    return { fplTeamFieldTabsData, leagueSize: ownershipData.leagueSize };
  };

  const { data: fplTeamFieldTabsData, isLoading } = useQuery({
    queryKey,
    queryFn: getFplTeamFieldTabsData,
    staleTime: 1000 * 60 * 30, // pervert from refetching the data
  });

  return { fplTeamFieldTabsData, isLoading };
};
