import { NavLink } from "react-router-dom";
import { css } from "@emotion/react";
import { secondaryBgColor, secondaryColor } from "../../config/theme";
import { ButtonBase, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { transition_all } from "../../config/styles";
import { useNavigationItem } from "../../utils/hooks/useNavigationItems";
import { useState } from "react";

/**
 * This is the main navigation bar in the app the fixed navigation bar at bottom of screen
 */
export const MainAppNavigationMenu = () => {
  const MAIN_NAVIGATION = useNavigationItem();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <nav css={navStyles}>
      <Stack
        component={"ul"}
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        {MAIN_NAVIGATION.map((navItem, index) => (
          <Stack
            key={index}
            width={"100%"}
            component={"li"}
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <ButtonBase css={navItemStyle}>
              {!navItem.children ? (
                <NavLink css={navItemStyle} to={navItem.path}>
                  <Stack
                    alignItems={"center"}
                    justifyContent={"center"}
                    gap={1}
                  >
                    <navItem.icon
                      fill={navItem.isActivePath ? secondaryColor : undefined}
                    />
                    <Typography
                      variant="h1"
                      css={navItemTitleStyles(navItem.isActivePath)}
                    >
                      {navItem.title}
                    </Typography>
                  </Stack>
                </NavLink>
              ) : (
                <>
                  <Stack
                    alignItems={"center"}
                    justifyContent={"center"}
                    gap={1}
                    onClick={handleClick}
                  >
                    <navItem.icon
                      fill={navItem.isActivePath ? secondaryColor : undefined}
                    />
                    <Typography
                      variant="h1"
                      css={navItemTitleStyles(navItem.isActivePath)}
                    >
                      {navItem.title}
                    </Typography>
                  </Stack>
                </>
              )}
            </ButtonBase>
          </Stack>
        ))}
      </Stack>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        css={extraMenuStyles}
        open={open}
        onClose={handleClose}
      >
        {MAIN_NAVIGATION.at(-1)?.children!.map((child, index) => (
          <MenuItem
            onClick={handleClose}
            key={index}
            css={extraMenuItemStyles(child.isActivePath)}
            component={NavLink}
            to={child.path}
          >
            <span>
              <child.icon
                fill={child.isActivePath ? secondaryColor : undefined}
              />
            </span>
            <Typography variant="h1">{child.title}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </nav>
  );
};

const navStyles = css`
  width: 100%;
  height: 4.875rem;
  background: ${secondaryBgColor};

  ul {
    width: 100%;
    height: 100%;

    li {
      flex-grow: 1;
      height: 100%;
    }
  }
`;

const navItemStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: ${secondaryBgColor};
  border: none;
`;

const navItemTitleStyles = (isPath: boolean) => css`
  ${transition_all}
  color: #8E8E8E;
  font-size: 0.675rem;
  opacity: 1;

  ${isPath &&
  css`
    color: ${secondaryColor};
    font-size: 0.875rem;
    opacity: 1;
  `}
`;

const extraMenuStyles = css`
  background: transparent;

  .MuiMenu-paper {
    background: ${secondaryBgColor};
    border-radius: 1rem;
  }
`;

const extraMenuItemStyles = (isPath: boolean) => css`
  background: ${secondaryBgColor};
  display: flex;
  color: #c0c0c0;
  gap: 1rem;

  h1 {
    font-size: 0.875rem;
  }

  span {
    width: 1.5rem;
    aspect-ratio: 1/1;
    object-fit: contain;
  }

  ${isPath &&
  css`
    color: ${secondaryColor};
  `};
`;
