import { axiosInstance } from "../config/axios.config";
import { OfficialLoginFormType } from "./validation";
import { appCrypto } from "../config/jsencrypt.config";

export const loginToOfficialFPl = async (
  fplAuth: Omit<OfficialLoginFormType, "generalErr">
) => {
  const fplAuthString = JSON.stringify(fplAuth);
  const encryptedFields = appCrypto.encrypt(fplAuthString);

  if (!encryptedFields) throw new Error("encryption failed ");

  await axiosInstance.post(
    "/auth/fpl/login",
    {
      encryptedFields,
      userAgent: navigator.userAgent,
    },
    { withCredentials: true }
  );
};
