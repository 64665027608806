import { Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { makeTransfersColumns } from "../../utils/tableColumns/makeTransfersColumns";
import {
  TransfersTableTitle,
  TransfersTableFilterMenu,
  TransfersPlayerOutSection,
  BankBar,
} from "./components";
import { css } from "@emotion/react";
import { navbarHeight } from "../../config/theme";
import { customScrollbar } from "../../config/styles";
import { useTransferTableContext } from "../../context/TransferTableContext";

export const TransferPlayersTablePage = () => {
  const { transferTeams, transferLeagueSize, transfersTableRows } =
    useTransferTableContext();

  return (
    <Stack css={rootStyles}>
      <div css={transfersPlayerOutSectionContainerStyles}>
        <TransfersPlayerOutSection />
      </div>
      <BankBar />
      <TransfersTableTitle />
      <TransfersTableFilterMenu />
      {transfersTableRows && transferLeagueSize && transferTeams && (
        <DataGrid
          disableColumnFilter
          disableColumnResize
          disableColumnSorting
          disableColumnMenu
          css={transferPlayersTableStyle}
          getRowId={(row) => row.id}
          paginationModel={{ page: 0, pageSize: 50 }}
          rows={transfersTableRows}
          columns={makeTransfersColumns(transferLeagueSize, transferTeams)}
        />
      )}
    </Stack>
  );
};

const rootStyles = css`
  position: relative;
  gap: 1rem;
  margin-top: calc(${navbarHeight} + 2.5rem);
`;

const transferPlayersTableStyle = css`
  color: white;
  border: none;
  font-size: clamp(0.875rem, 1.5vw, 1.2rem);

  /** Table Header */
  .MuiDataGrid-columnHeaders {
    div {
      background-color: transparent !important;
    }
  }

  .MuiDataGrid-virtualScroller,
  .MuiDataGrid-main,
  .MuiDataGrid-virtualScrollerContent,
  .MuiDataGrid-footerContainer,
  .MuiDataGrid-cell {
    border: none !important;
  }

  .MuiDataGrid-topContainer::after,
  .MuiDataGrid-filler {
    display: none !important;
  }

  .MuiDataGrid-row {
    cursor: pointer;
    border-bottom: 2px solid #2e2f3e;
  }

  .MuiDataGrid-sortIcon {
    color: white;
  }

  .MuiDataGrid-columnSeparator {
    display: none;
  }

  .MuiDataGrid-scrollbar--vertical {
    ${customScrollbar}
  }

  .MuiDataGrid-scrollbar {
    z-index: 0; // fix scrollbar in mini league tables specifically
  }

  .MuiDataGrid-scrollbar--horizontal {
    margin-block: 0.5rem;
    /* top: 0rem; */
    ${customScrollbar}
  }

  .MuiDataGrid-main:has(.MuiDataGrid-scrollbar--horizontal)
    > .MuiDataGrid-virtualScroller {
    margin-block: 0.5rem 1rem;
  }

  // Remove border of scrollbar container inside cell
  .MuiDataGrid-scrollbarFiller {
    border: none !important;
  }
`;

const transfersPlayerOutSectionContainerStyles = css`
  position: fixed;
  top: calc(${navbarHeight} - 5px);
  left: 0;
  z-index: 1000;
  width: 100%;
`;
