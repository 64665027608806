import { Stack, Typography } from "@mui/material";
import { AppModal, Button } from "../../../components";
import { usePlayChips, useTeamContext } from "../../../context/TeamContext";
import { css } from "@emotion/react";
import { PICK_TEAM_PAGE_CONSTANTS } from "../../../constants/view-team-pages.constants";
import { useState } from "react";
import { poppinsFontFamily } from "../../../config/styles";

/**
 * A component to render the confirm picks changes section on the team page
 *
 * This component renders a cancel and confirm button for the picks changes made
 * on the team page
 */
export const ConfirmChangesSection = () => {
  const { resetPicksChanges, confirmPicksChanges, hasChanged } =
    useTeamContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isChipPlayed } = usePlayChips();

  const handleCancelClick = () => {
    if (isChipPlayed) {
      setIsModalOpen(true);
    } else {
      resetPicksChanges();
    }
  };

  const handleConfirmClick = () => {
    resetPicksChanges();
    setIsModalOpen(false);
  };

  return (
    <Stack css={rootStyles}>
      <Button
        disabled={!hasChanged}
        button="outline"
        color="white"
        onClick={handleCancelClick}
      >
        {PICK_TEAM_PAGE_CONSTANTS.CONFIRM_BTNS.CANCEL}
      </Button>
      <Button
        disabled={!hasChanged}
        button="gradient"
        onClick={confirmPicksChanges}
      >
        {PICK_TEAM_PAGE_CONSTANTS.CONFIRM_BTNS.CONFIRM}
      </Button>

      <AppModal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Stack css={modalContainerStyles}>
          <Typography css={titleStyles}>
            {PICK_TEAM_PAGE_CONSTANTS.CANCEL_MODAL_CONTENT.TITLE}
          </Typography>
          <Typography css={descStyles}>
            {PICK_TEAM_PAGE_CONSTANTS.CANCEL_MODAL_CONTENT.DESCRIPTION}
          </Typography>

          <Stack css={btnsContainerStyles}>
            <Button
              button="outline"
              color="white"
              onClick={() => setIsModalOpen(false)}
            >
              {PICK_TEAM_PAGE_CONSTANTS.CANCEL_MODAL_CONTENT.CANCEL_BTN}
            </Button>
            <Button button="gradient" onClick={handleConfirmClick}>
              {PICK_TEAM_PAGE_CONSTANTS.CANCEL_MODAL_CONTENT.CONFIRM_BTN}
            </Button>
          </Stack>
        </Stack>
      </AppModal>
    </Stack>
  );
};

const rootStyles = css`
  gap: 1rem;
  flex-direction: row;
`;

const modalContainerStyles = css`
  padding: 1rem;
`;

const titleStyles = css`
  ${poppinsFontFamily}
  font-size: clamp(1.2rem, 5vw, 1.75rem);
  font-weight: 700;
`;

const descStyles = css`
  ${poppinsFontFamily}
  font-size: clamp(0.875rem, 2vw, 1rem);
`;

const btnsContainerStyles = css`
  flex-direction: row;
  gap: 1rem;
  margin-top: 1.5rem;
  justify-content: end;
`;
