import { Box, Stack, Typography } from "@mui/material";
import {
  AppTable,
  AppTableSkeleton,
  ShowMeSomethingCoolBtn,
  ChangeIndicator,
} from "../../../components";
import { css } from "@emotion/react";
import { columnNumber } from "../../../utils/muiTableColumns";
import {
  teamColumn,
  extraColumns,
  liveGWCaptainPointsColumn,
} from "../../../utils/tableColumns/miniLeagueTableColumns";
import { homeCardBgColor, title } from "../../../config/styles";
import { Link, useSearchParams } from "react-router-dom";
import {
  IMiniLeagueStanding,
  useGetMiniLeagueData,
} from "../../../utils/hooks/useGetMiniLeagueData";
import { PATH_PARAMS } from "../../../config/routes";
import { useGetUrl } from "../../../utils/hooks/useGetUrl";
import { MINI_LEAGUE_SUMMARY } from "../../../constants/home-page.constants";
import { useGlobalInfoContext } from "../../../context/GlobalInfoContext";
import { MiniLeagueSummaryErrorCard } from "./MiniLeagueSummaryErrorCard";
import { useNavigateState } from "../../../utils/hooks/useNavigateState";
import mostHurtful from "../../../assets/cool-feature/most-hurtful.svg";
import captainArmband from "../../../assets/cool-feature/captain-armband.svg";
import { SHOW_ME_COOL_BTN } from "../../../constants/global.constants";

const columns = [
  {
    ...columnNumber,
    renderCell: ({ row }: { row: IMiniLeagueStanding }) => (
      <Stack
        height={"100%"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        gap={2}
      >
        <ChangeIndicator currentValue={row.rank} prevValue={row.last_rank} />
        {row.rank_sort}
      </Stack>
    ),
  },
  teamColumn,
  ...extraColumns,
  liveGWCaptainPointsColumn,
];

/** Renders the Mini league table in the home page or an error card if game week is not live game week*/
export const MiniLeagueSummary = () => {
  const { miniLeagueStandings } = useGetMiniLeagueData({
    pageNumber: 1,
  });
  const { liveGameWeek } = useGlobalInfoContext();
  const [searchParams] = useSearchParams();
  const {
    getMiniLeagueStandingsPath,
    getTeamViewPath,
    getMiniLeagueCaptainsPath,
    getMiniLeagueMostHurtfulPath,
  } = useGetUrl();
  const navigate = useNavigateState();

  if (
    searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK) !== String(liveGameWeek)
  ) {
    return <MiniLeagueSummaryErrorCard />;
  }

  const handleRowClick = (fplManagerId: string) => {
    navigate(getTeamViewPath(fplManagerId));
  };

  const coolList = [
    {
      title: "League Captains",
      description:
        "Find out how many times the most popular captains were picked by each manager.",
      link: getMiniLeagueCaptainsPath(),
      img: captainArmband,
    },
    {
      title: "Most hurtful players",
      description:
        "Players you don’t own that are the most popular with your selected league.",
      link: getMiniLeagueMostHurtfulPath(),
      img: mostHurtful,
    },
  ];

  return (
    <Box css={rootStyle}>
      <Stack css={tableTitleContainerStyle}>
        <Typography css={tableTitleStyle}>
          {MINI_LEAGUE_SUMMARY.TITLE}
          {searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK)}
        </Typography>

        <Link to={getMiniLeagueStandingsPath()}>
          {MINI_LEAGUE_SUMMARY.LINK_TITLE}
        </Link>
      </Stack>

      {miniLeagueStandings ? (
        <AppTable
          getRowHeight={() => "auto"}
          pageSize={51}
          hideFooter
          css={tableStyles}
          onRowClick={({ row }) => handleRowClick(row.entry)}
          columns={columns}
          rows={miniLeagueStandings}
        />
      ) : (
        <AppTableSkeleton />
      )}

      <div css={btnContainer}>
        <ShowMeSomethingCoolBtn
          title={SHOW_ME_COOL_BTN.MINI_LEAGUE.TITLE}
          featuresList={coolList}
        />
      </div>
    </Box>
  );
};

const rootStyle = css`
  ${homeCardBgColor}
  border-radius: 1rem;
  gap: 1rem;
  overflow: hidden;
`;

const tableStyles = css`
  height: 50vh;
`;

const tableTitleStyle = css`
  ${title}
`;

const tableTitleContainerStyle = css`
  padding: 1rem;
  border-radius: 1rem 1rem 0 0;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  a {
    text-decoration: underline;
    font-weight: 200;
    color: white;
  }
`;

const btnContainer = css`
  padding: 1rem;
`;
