import { useState } from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import { css } from "@emotion/react";
import searchIcon from "../../../assets/icons/search-gradient-icon.svg";
import sortIcon from "../../../assets/icons/sort-gradient-icon.svg";
import sortOrderGradient from "../../../assets/icons/sort-order-gradient.svg";
import sortOrderSolid from "../../../assets/icons/sort-order-solid.svg";
import { transition_all } from "../../../config/styles";
import { secondaryColor } from "../../../config/theme";
import { AppSelect } from "../../../components";
import { useTransferTableContext } from "../../../context/TransferTableContext";
import { eplPlayerSummaryCoreColumns } from "../../../utils/tableColumns/eplPLayerSummaryColumns";
import { ITransfersEplPlayerInfo } from "../../../utils/hooks/useGetTransfersEplPlayerInfo";
import { AppBottomModal } from "../../../components/AppBottomModal";

export const TransfersTableTitle = () => {
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
  const [localSortConfig, setLocalSortConfig] = useState<{
    field: keyof ITransfersEplPlayerInfo["eplPlayers"][number];
    direction: "asc" | "desc";
  } | null>(null);

  const { transfersTableFiltersState, setTransfersTableFiltersState } =
    useTransferTableContext();

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTransfersTableFiltersState((prev) => ({
      ...prev,
      searchQuery: e.target.value,
    }));
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.currentTarget.blur();
    }
  };

  const handleSort = ({
    value,
    sortOrder,
  }: {
    value?: string;
    sortOrder?: "asc" | "desc";
  }) => {
    setLocalSortConfig((prev) => ({
      field:
        (value as keyof ITransfersEplPlayerInfo["eplPlayers"][number]) ||
        prev?.field ||
        transfersTableFiltersState.sortConfig.field,
      direction:
        sortOrder ||
        prev?.direction ||
        transfersTableFiltersState.sortConfig.direction,
    }));
  };

  const handleApplySort = () => {
    if (localSortConfig) {
      setTransfersTableFiltersState((prev) => ({
        ...prev,
        sortConfig: localSortConfig,
      }));
    }
    setLocalSortConfig(null);
    setIsBottomSheetOpen(false);
  };

  const sortOptions = eplPlayerSummaryCoreColumns.map((column) => ({
    label: column.description!,
    value: column.field!,
  }));

  const sortOrder =
    localSortConfig?.direction ||
    transfersTableFiltersState.sortConfig.direction;

  return (
    <Stack css={rootContainerStyles}>
      <Typography variant="h1" css={titleStyles}>
        Select Player
      </Typography>

      <Stack flex={1} justifyContent={"flex-end"} direction={"row"} gap={1}>
        <label css={transfersFiltersInput}>
          <img src={searchIcon} alt="search icon" />
          <input
            value={transfersTableFiltersState.searchQuery}
            placeholder="Search for a player name, team, etc.."
            onChange={handleSearch}
            onKeyDown={handleKeyDown}
          />
        </label>

        <IconButton
          css={sortBtnStyles}
          onClick={() => setIsBottomSheetOpen(true)}
        >
          <img src={sortIcon} alt="search icon" />
        </IconButton>
      </Stack>

      <AppBottomModal
        isOpen={isBottomSheetOpen}
        onClose={() => setIsBottomSheetOpen(false)}
        iconImage={sortIcon}
        title="Sort by"
        onApplyClick={handleApplySort}
        disabledApply={!localSortConfig}
        extraButtons={
          <Stack css={sortOrderContainerStyles}>
            <IconButton
              data-order="desc"
              onClick={() => handleSort({ sortOrder: "desc" })}
              css={sortOrderGradientStyles(sortOrder === "desc")}
            >
              {sortOrder === "desc" ? (
                <img
                  style={{ rotate: "180deg" }}
                  src={sortOrderSolid}
                  alt="search icon"
                />
              ) : (
                <img src={sortOrderGradient} alt="search icon" />
              )}
            </IconButton>

            <IconButton
              onClick={() => handleSort({ sortOrder: "asc" })}
              data-order="asc"
              css={sortOrderGradientStyles(sortOrder === "asc")}
            >
              {sortOrder === "asc" ? (
                <img src={sortOrderSolid} alt="search icon" />
              ) : (
                <img
                  style={{ rotate: "180deg" }}
                  src={sortOrderGradient}
                  alt="search icon"
                />
              )}
            </IconButton>
          </Stack>
        }
      >
        <Stack css={sortOptionsContainerStyles}>
          <AppSelect
            selectedOption={
              localSortConfig?.field ||
              transfersTableFiltersState.sortConfig.field
            }
            options={sortOptions}
            onSortOptionChange={(value) => handleSort({ value })}
          />
        </Stack>
      </AppBottomModal>
    </Stack>
  );
};

const rootContainerStyles = css`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;

const titleStyles = css`
  font-size: 1.25rem;
  font-weight: 900;
`;

const filters = css`
  display: flex;
  align-items: center;
  background-color: #222232;
  width: fit-content;
  border-radius: 0.75rem;
  padding: 0.5rem;
  height: 3rem;
  width: 3rem;

  img {
    min-width: 1.8rem;
    max-width: 1.8rem;
  }
`;

const sortBtnStyles = css`
  ${filters}
`;

const transfersFiltersInput = css`
  ${filters}
  ${transition_all}

  font-weight: 600;
  cursor: pointer;

  :has(input:focus),
  :not(:has(input:placeholder-shown)) {
    gap: 0.5rem;
    max-width: 16rem;
    flex: 1;
  }

  input {
    ${transition_all}
    border-radius: 0.5rem;
    background-color: transparent;
    letter-spacing: 0.07rem;
    border: none;
    color: inherit;
    width: 0;
    height: 100%;
    padding-inline: 0.5rem;
  }

  input::placeholder {
    color: white;
    opacity: 0.5;
  }

  input:not(:placeholder-shown) {
    outline: grey solid 1px;
    width: 100%;
  }

  input:focus {
    outline: ${secondaryColor} solid 2px;
    width: 100%;
  }
`;

const sortOrderGradientStyles = (selected: boolean) => css`
  padding: 0.5rem 1rem;
  border-radius: 9999px;

  background-color: #222232;
  :hover {
    background-color: #34344d;
  }

  ${selected &&
  css`
    background-color: white;
    :hover {
      background-color: #ffffffd6;
    }
  `}

  img {
    width: 1.5rem;
  }
`;

const sortOrderContainerStyles = css`
  flex-direction: row;
  gap: 0.5rem;
`;

const sortOptionsContainerStyles = css`
  padding: 1rem;
  overflow-y: auto;
`;
