import { css } from "@emotion/react";
import { IconButton, Stack, Typography } from "@mui/material";
import { transition_all } from "../config/styles";
import { useEffect, useRef } from "react";
import resetIcon from "../assets/view-team/reset-icon.svg";
import { Button } from "./Button";

interface AppBottomModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  iconImage?: string;
  title?: string;
  onResetClick?: () => void;
  extraButtons?: React.ReactNode;
  onApplyClick?: () => void;
  disabledApply?: boolean;
}

export const AppBottomModal = ({
  isOpen,
  onClose,
  children,
  iconImage,
  title,
  onResetClick,
  extraButtons,
  onApplyClick,
  disabledApply,
}: AppBottomModalProps) => {
  const bottomSheetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen && bottomSheetRef.current) {
      bottomSheetRef.current.style.display = "flex";
    }
  }, [isOpen]);

  return (
    <Stack
      css={[
        bottomSheetContainerStyles,
        isOpen && bottomSheetOpenStyles,
        !isOpen && bottomSheetCloseStyles,
      ]}
      ref={bottomSheetRef}
      onClick={onClose}
      onTransitionEnd={() => {
        if (!isOpen && bottomSheetRef.current) {
          bottomSheetRef.current.style.display = "none";
        }
      }}
    >
      <Stack
        onClick={(e) => e.stopPropagation()}
        css={bottomSheetContentStyles}
      >
        <Stack css={bottomSheetTitleStyles}>
          <img src={iconImage} alt="close" />
          <Typography variant="h1">{title}</Typography>

          {extraButtons}

          {onResetClick && (
            <IconButton css={resetBtnStyles} onClick={onResetClick}>
              Reset <img src={resetIcon} alt="close" />
            </IconButton>
          )}
        </Stack>
        {children}
        {onApplyClick && (
          <Button
            button="gradient"
            onClick={onApplyClick}
            disabled={disabledApply}
          >
            Apply
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

const bottomSheetTitleStyles = css`
  padding: 1rem;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: start;
  h1 {
    flex: 1;
    color: #fff;
    font-size: 1.5rem;
    font-weight: 900;
  }
`;

const resetBtnStyles = css`
  margin-left: auto;
  color: white;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  border-radius: 9999px;
  background-color: #222232;
  :hover {
    background-color: #34344d;
  }

  img {
    margin-left: 0.5rem;
    width: 1rem;
    height: 1rem;
  }
`;

const bottomSheetContainerStyles = css`
  align-items: end;
  justify-content: end;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  transform: translateY(100%);
  ${transition_all}
`;

const bottomSheetOpenStyles = css`
  display: flex;
  transform: translateY(0);
`;

const bottomSheetCloseStyles = css`
  display: none;
`;

const bottomSheetContentStyles = css`
  width: 100%;
  max-height: 75vh;
  max-height: 75svh;
  background-color: #222232;
  border-radius: 1rem 1rem 0 0;
  padding: 1rem;
  padding-top: 2rem;
  gap: 1rem;
`;
