import { css } from "@emotion/react";
import { Box, IconButton, Modal, Stack, Typography } from "@mui/material";
import { Button, Skeleton } from "../../../components";
import {
  FPL_CHIPS,
  FPL_CHIPS_KEYS_TYPE,
  FPL_CHIPS_STATUS,
} from "../../../constants/app.constants";
import {
  bgColor,
  bgColorDark,
  redColor,
  tableHeaderBgColor,
} from "../../../config/theme";
import { useEffect, useState } from "react";
import { ITransfersTeamInfo } from "../../../utils/hooks/useGetTransfersTeamInfo";
import { yellowPickGradient } from "../../../config/styles";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { usePlayChips, useTeamContext } from "../../../context/TeamContext";
import { useNavigateState } from "../../../utils/hooks/useNavigateState";
import { useGetUrl } from "../../../utils/hooks/useGetUrl";
import { useLocation } from "react-router-dom";

type chipType = (typeof FPL_CHIPS)[FPL_CHIPS_KEYS_TYPE] &
  ITransfersTeamInfo["chips"][0];
export const ChipsSection = () => {
  const [chips, setChips] = useState<chipType[] | null>(null);
  const [selectedChip, setSelectedChip] = useState<chipType | null>(null);
  const { pickTeam } = useTeamContext();
  const { activateChip, deactivateChip } = usePlayChips();
  const navigate = useNavigateState();
  const { getMakeTransfersPath } = useGetUrl();
  const { pathname } = useLocation();
  const isPickTeam = pathname.includes("pick-team");
  const isTransfersChip = ["freehit", "wildcard"].includes(
    selectedChip?.name ?? ""
  );

  useEffect(() => {
    if (pickTeam) {
      const newChips = Object.entries(FPL_CHIPS).map(([key, chip]) => {
        const fplChipDate = pickTeam.chips.find((c) => c.name === key)!;
        return {
          ...chip,
          ...fplChipDate,
        };
      });

      setChips(newChips);
    }
  }, [pickTeam]);

  const handlePlayChip = () => {
    if (!selectedChip || !pickTeam) return;
    if (isPickTeam && isTransfersChip) {
      navigate(getMakeTransfersPath());
    } else {
      activateChip(selectedChip.name);
    }
    setSelectedChip(null);
  };

  const handleCancelChip = () => {
    if (!selectedChip || !pickTeam) return;
    deactivateChip(selectedChip.name);
    setSelectedChip(null);
  };

  if (!chips)
    return (
      <Box component={"ul"} css={chipsContainerStyles}>
        {Array.from({ length: 5 }, (_, index) => (
          <Skeleton
            key={index}
            component={"li"}
            css={[
              chipStyles(""),
              css`
                background-color: ${tableHeaderBgColor};
                height: 8rem !important;
              `,
            ]}
          />
        ))}
      </Box>
    );

  return (
    <Box component={"ul"} css={chipsContainerStyles}>
      {chips.map((chip) => (
        <Stack
          onClick={() => setSelectedChip(chip)}
          component={"li"}
          key={chip.id}
          css={chipStyles(chip.status_for_entry)}
        >
          <img src={chip.img} />
          <Typography>{chip.full_name}</Typography>
          <Button button="gradient">
            {chip.played_by_entry.at(-1) && chip.status_for_entry !== "active"
              ? `Played GW${chip.played_by_entry.at(-1)}`
              : FPL_CHIPS_STATUS[chip.status_for_entry]}
          </Button>
        </Stack>
      ))}

      <Modal
        open={Boolean(selectedChip)}
        css={modelStyles}
        onClose={() => setSelectedChip(null)}
      >
        <>
          {selectedChip && (
            <Stack css={modelContentStyles}>
              <IconButton
                css={closeButtonStyles}
                onClick={() => setSelectedChip(null)}
              >
                <CloseRoundedIcon />
              </IconButton>
              <img src={selectedChip.img} />
              <Stack>
                <Typography variant="h1">{selectedChip.full_name}</Typography>
                <Typography variant="h2">{selectedChip.description}</Typography>
                {selectedChip.extraInfo && (
                  <Typography variant="h2">{selectedChip.extraInfo}</Typography>
                )}
                <Typography variant="h2">{selectedChip.cancelAble}</Typography>
                {selectedChip.waring && (
                  <Typography>{selectedChip.waring}</Typography>
                )}
                {selectedChip.status_for_entry !== "unavailable" &&
                  (selectedChip.status_for_entry === "active" ? (
                    <Button
                      button="outline"
                      color={redColor}
                      onClick={handleCancelChip}
                    >
                      Cancel
                    </Button>
                  ) : (
                    <Button button="gradient" onClick={handlePlayChip}>
                      {isPickTeam && isTransfersChip
                        ? "Go to Transfers"
                        : "Play"}
                    </Button>
                  ))}
              </Stack>
            </Stack>
          )}
        </>
      </Modal>
    </Box>
  );
};

const modelStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;

  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.082);
    backdrop-filter: blur(2px);
  }
`;

const modelContentStyles = css`
  position: relative;
  background-color: ${bgColorDark};
  border-radius: 1rem;
  padding: 2rem 1rem;
  gap: 2rem;

  width: calc(100vw - 2rem);
  max-width: 35rem;

  align-items: center;
  justify-content: center;

  div {
    background-color: ${bgColor};
    border-radius: 1rem;
    padding: 1rem;
    gap: 1rem;
    align-items: center;
    justify-content: center;

    h1 {
      font-weight: 700;
      font-size: clamp(1.8rem, 3vw, 2.2rem);
    }

    h2 {
      text-align: center;
      font-size: clamp(1rem, 1.5vw, 1.2rem);
    }

    p {
      text-align: center;
      font-size: clamp(1rem, 1.5vw, 1.2rem);
      font-weight: 700;
    }
  }

  img {
    width: 4rem;
  }
`;

const chipsContainerStyles = css`
  /* flex-direction: row; */
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
  overflow-x: auto;
`;

const chipStyles = (
  status: ITransfersTeamInfo["chips"][0]["status_for_entry"] | ""
) => css`
  flex: 1;
  background-color: white;
  padding: 1rem 0.5rem;
  gap: 0.5rem;
  border-radius: 0.7rem;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  width: clamp(8rem, 15vw, 10rem);

  p {
    color: black;
    font-weight: 700;
    text-align: center;
    font-size: clamp(0.75rem, 1.5vw, 1rem);
  }

  button {
    padding: 0.1rem;
    height: auto;
    border-radius: 0.5rem;
    font-size: clamp(0.75rem, 1.5vw, 1rem);
  }

  img {
    width: 2rem;
    height: 2rem;
  }

  ${status === "unavailable" &&
  css`
    background-color: ${tableHeaderBgColor};

    p {
      color: white;
    }

    button {
      background: ${bgColor};
      color: white;
    }
  `}

  ${status === "active" &&
  css`
    button {
      /* ${yellowPickGradient} */
      background: none;
      text-decoration: underline;
    }
  `}
`;

const closeButtonStyles = css`
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 3;
  color: white;
  background-color: #2a292954;
`;
