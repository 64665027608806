import { GridColDef } from "@mui/x-data-grid";
import { ITransfersEplPlayerInfo } from "../hooks/useGetTransfersEplPlayerInfo";
import { TransfersEplPlayerCell } from "../../components/table-cells";
import { Box, Stack } from "@mui/material";
import { css } from "@emotion/react";
import { PlayerOwnership } from "../../components";
import { eplPlayerSummaryCoreColumns } from "./eplPLayerSummaryColumns";

export const makeTransfersColumns = (
  leagueSize: ITransfersEplPlayerInfo["leagueSize"],
  teams: ITransfersEplPlayerInfo["teams"]
): GridColDef<ITransfersEplPlayerInfo["eplPlayers"][0]>[] => {
  return [
    {
      field: "player",
      headerName: "Player",
      width: 200,
      renderCell: ({ row }) => {
        const team = teams.find((team) => team.id === row.team)!;
        return (
          <TransfersEplPlayerCell
            name={row.first_name + " " + row.second_name}
            position={row.position}
            teamName={row.teamName}
            tShirtLink={
              row.position === "GK" ? team.gkShirtLink : team.shirtLink
            }
          />
        );
      },
    },
    {
      field: "price",
      headerName: "Price",
      renderCell: ({ row }) => `${row.price / 10}£`,
      width: 100,
    },
    // {
    //   field: "fixturesDifficulty",
    //   headerName: "Upcoming",
    //   width: 130,
    //   renderCell: ({ row }) => (
    //     <Stack css={fixtureDifficultyStyles}>
    //       <Box>
    //         <FixtureDifficulty fixtureDifficulty={row.fixturesDifficulty} />
    //       </Box>
    //     </Stack>
    //   ),
    // },
    // {
    //   field: "eplPlayerPointsHistory",
    //   headerName: "History",
    //   width: 130,
    //   renderCell: ({ row }) => (
    //     <Stack css={fixtureDifficultyStyles}>
    //       <Box>
    //         <PlayerHistory history={row.eplPlayerPointsHistory} />
    //       </Box>
    //     </Stack>
    //   ),
    // },
    {
      field: "pickCount",
      headerName: "Ownership",
      width: 130,
      renderCell: ({ row }) => (
        <Stack css={fixtureDifficultyStyles}>
          <Box>
            <PlayerOwnership ownerShip={`${row.pickCount} / ${leagueSize}`} />
          </Box>
        </Stack>
      ),
    },
    ...eplPlayerSummaryCoreColumns,
  ];
};

const fixtureDifficultyStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-inline: 0.5rem;

  div {
    width: 100%;
    border-radius: 0.75rem;
    overflow: hidden;
    height: 1.75rem;
  }
`;
