export const getCookies = () => {
  const pairs = document.cookie.split(";");

  // @ts-ignore
  const cookies: { signedIn: string; [key: string]: string } = {};

  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split("=");
    cookies[(pair[0] + "").trim()] = unescape(pair.slice(1).join("="));
  }

  return cookies;
};
