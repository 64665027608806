import { SyntheticEvent } from "react";
import { primaryGradient } from "../config/styles";
import { darkGray, white } from "../config/theme";
import { css } from "@emotion/react";
import { Tab, Tabs } from "@mui/material";
import { Link } from "react-router-dom";

interface IAppTabBar {
  value: string;
  tabs: {
    label: string;
    value: string;
    state?: {};
  }[];
  isLinkTab?: boolean;
  onTabsChange?: (event: SyntheticEvent<Element, Event>, value: string) => void;
}

/** Renders a tab bar used in Single Fixture Results page and EPLSummary Popup */
export const AppTabsBar = (props: IAppTabBar) => {
  return (
    <Tabs
      onChange={props.onTabsChange}
      css={tabsContainerStyles}
      value={props.value}
    >
      {props.tabs.map((tab) => (
        /** @ts-ignore add to prop for Link */
        <Tab
          key={tab.value}
          css={tabStyles}
          LinkComponent={props.isLinkTab ? Link : undefined}
          label={tab.label}
          value={tab.value}
          to={props.isLinkTab ? tab.value : undefined}
          state={tab.state}
        />
      ))}
    </Tabs>
  );
};

const tabsContainerStyles = css`
  background-color: #222232;

  .MuiTabs-indicator {
    display: none;
  }
`;

const tabStyles = css`
  flex: 1;
  max-width: none;
  font-weight: 600;
  color: ${darkGray};
  text-transform: capitalize;

  &.Mui-disabled {
    color: ${darkGray};
  }

  &.Mui-selected {
    color: ${white};
    position: relative;
    ::after {
      position: absolute;
      content: "";
      ${primaryGradient};
      width: 70%;
      height: 0.2rem;
      bottom: 0;
      left: 50%;
      translate: -50% 0;
      border-radius: 999px;
    }
  }
`;
