import { useSearchParams } from "react-router-dom";
import { QUERY_PARAMS } from "../../../config/routes";
import { useGetTransfersTeamInfo } from "../../../utils/hooks/useGetTransfersTeamInfo";
import { css } from "@emotion/react";
import { Box, Stack, Typography } from "@mui/material";
import { PlayerHistory, PlayerOwnership } from "../../../components";
import { TransfersEplPlayerCell } from "../../../components/table-cells";
import { hideScrollbar } from "../../../config/styles";
import { bgColor, secondaryBgColor } from "../../../config/theme";
import { useGetFplTeamFieldTabsData } from "../../../utils/hooks/useGetFplTeamFieldTabsData";
import { FixtureDifficulty } from "./FixtureDifficulty";

export const TransfersPlayerOutSection = () => {
  const { transfersTeamInfo } = useGetTransfersTeamInfo();

  const [searchParams] = useSearchParams();
  const eplPlayerId = searchParams.get(QUERY_PARAMS.EPL_PLAYER_INFO_MODAL)!;

  const transfersOutPlayer = transfersTeamInfo?.picks.find(
    (pick) => pick.element === Number(eplPlayerId)
  );

  const { fplTeamFieldTabsData } = useGetFplTeamFieldTabsData();
  const fieldData =
    transfersOutPlayer &&
    fplTeamFieldTabsData &&
    fplTeamFieldTabsData.fplTeamFieldTabsData[transfersOutPlayer.element];

  if (!fieldData || !transfersOutPlayer) return null;

  return (
    <Box css={transfersOutPlayerContainerStyles}>
      <Stack>
        <Box>
          <Typography css={transfersOutPlayerSectionTitleStyles}>
            Player
          </Typography>
          <TransfersEplPlayerCell
            name={transfersOutPlayer.name}
            position={transfersOutPlayer.field_position}
            teamName={transfersOutPlayer.teamName}
            tShirtLink={
              transfersOutPlayer.field_position === "GK"
                ? transfersOutPlayer.gk_shirt_link
                : transfersOutPlayer.shirt_link
            }
          />
        </Box>

        <Box>
          <Typography css={transfersOutPlayerSectionTitleStyles}>
            Price
          </Typography>
          <Typography>{`${transfersOutPlayer.selling_price / 10}£`}</Typography>
        </Box>

        <Box>
          <Typography css={transfersOutPlayerSectionTitleStyles}>
            Upcoming
          </Typography>
          <Stack css={fixtureDifficultyStyles}>
            <Box>
              <FixtureDifficulty
                fixtureDifficulty={fieldData.upcomingFixtures}
              />
            </Box>
          </Stack>
        </Box>

        <Box>
          <Typography css={transfersOutPlayerSectionTitleStyles}>
            History
          </Typography>
          <Stack css={fixtureDifficultyStyles}>
            <Box>
              <PlayerHistory history={fieldData.pointsHistory} />
            </Box>
          </Stack>
        </Box>

        <Box>
          <Typography css={transfersOutPlayerSectionTitleStyles}>
            Ownership
          </Typography>
          <Stack css={fixtureDifficultyStyles}>
            <Box>
              <PlayerOwnership
                bgColor={bgColor}
                ownerShip={`${fieldData.ownership} / ${fplTeamFieldTabsData.leagueSize}`}
              />
            </Box>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

const transfersOutPlayerContainerStyles = css`
  width: 100%;
  background-color: ${secondaryBgColor};
  overflow-x: auto;
  overflow-y: hidden;
  ${hideScrollbar}
  padding: 1.5rem 1rem;
  border-radius: 0 0 1.5rem 1.5rem;

  & > div {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    width: fit-content;

    & > *:first-of-type {
      min-width: fit-content;
    }

    & > *:nth-of-type(2) {
      width: 80px;
    }

    & > *:nth-of-type(3) {
      width: 100px;
    }

    & > *:nth-of-type(4) {
      width: 100px;
    }

    & > *:nth-of-type(5) {
      width: 100px;
    }
  }
`;

const transfersOutPlayerSectionTitleStyles = css`
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
`;

const fixtureDifficultyStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  div {
    width: 100%;
    border-radius: 0.75rem;
    overflow: hidden;
    height: 1.75rem;
  }
`;
