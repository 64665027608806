import { CHIPS } from "../../constants/highlightsPage.constants";
import { ICategory } from "../hooks/useGetHighlightsTabs";

export type AtLeastOne<T, Keys extends keyof T = keyof T> = Partial<T> &
  { [K in Keys]: Required<Pick<T, K>> }[Keys];

export const IMPORTANT_SUFFIX = {
  points: "pts",
  money: "M",
  rank: "",
  int: "",
  other: "",
} as const;

export type TCategoryId = keyof typeof CHIPS;
export type TImportantSuffix = keyof typeof IMPORTANT_SUFFIX;

export interface IClassicLeagues {
  id: number;
  name: string;
  rank_count?: number;
  entry_rank?: number;
  entry_last_rank?: number;
  rank_change?: {
    isUp: boolean;
    change_count: number;
  };
}

export interface IHighlightCardDetails {
  id: number;
  entry: number;
  entry_name: string;
  player_name: string;
  rank: number;
  [key: string]: string | number;
}

export interface IAllHighlights {
  id: string;
  view_from_bottom_desc: string;
  view_from_top_desc: string;
  view_from_bottom_invalid?: boolean;
  view_from_top_invalid?: boolean;
  is_not_available: boolean;
  not_available_desc?: string;
  not_available_title?: string;
  details: IHighlightCardDetails[];
}

export interface IImportantProperties {
  key: string;
  display_name: string;
  type: TImportantSuffix;
  order: number;
  color: "primary" | "white";
}

export interface IHighlightsInfo {
  _id: string;
  highlight_id: string;
  category_id: TCategoryId;
  display_name: string;
  description: string;
  icon: string;
  view_from: "top" | "bottom";
  highlight_order: number;
  important_properties: IImportantProperties[];
  invalid: string;
}

export interface IHighlightCard extends IAllHighlights, IHighlightsInfo {}

export interface ISingleHighlightCard
  extends IAllHighlights,
    IHighlightsInfo,
    ICategory {}

export interface IFplManager {
  id: number;
  event_total: number;
  player_name: string;
  rank: number;
  last_rank: number;
  rank_sort: number;
  total: number;
  entry: number;
  entry_name: string;
}

export interface IFplAllInfoPhase {
  id: number;
  name: string;
  start_event: number;
  stop_event: number;
}

export interface IGlobalInfo {
  phases: IFplAllInfoPhase[];
  current_gw: number;
  allInfo: {
    elements: {
      position: FILED_PLAYER_TYPES;
      chance_of_playing_next_round: number;
      chance_of_playing_this_round: number;
      code: number;
      cost_change_event: number;
      cost_change_event_fall: number;
      cost_change_start: number;
      cost_change_start_fall: number;
      dreamteam_count: number;
      element_type: number;
      ep_next: string;
      ep_this: string;
      event_points: number;
      first_name: string;
      form: string;
      id: number;
      in_dreamteam: boolean;
      news: string;
      news_added: string;
      now_cost: number;
      photo: string;
      points_per_game: string;
      second_name: string;
      selected_by_percent: string;
      special: boolean;
      squad_number: null | number;
      status: string;
      team: number;
      team_code: number;
      total_points: number;
      transfers_in: number;
      transfers_in_event: number;
      transfers_out: number;
      transfers_out_event: number;
      value_form: string;
      value_season: string;
      web_name: string;
      minutes: number;
      goals_scored: number;
      assists: number;
      clean_sheets: number;
      goals_conceded: number;
      own_goals: number;
      penalties_saved: number;
      penalties_missed: number;
      yellow_cards: number;
      red_cards: number;
      saves: number;
      bonus: number;
      bps: number;
      influence: string;
      creativity: string;
      threat: string;
      ict_index: string;
      starts: number;
      expected_goals: string;
      expected_assists: string;
      expected_goal_involvements: string;
      expected_goals_conceded: string;
      influence_rank: number;
      influence_rank_type: number;
      creativity_rank: number;
      creativity_rank_type: number;
      threat_rank: number;
      threat_rank_type: number;
      ict_index_rank: number;
      ict_index_rank_type: number;
      corners_and_indirect_freekicks_order: null | number;
      corners_and_indirect_freekicks_text: string;
      direct_freekicks_order: null | number;
      direct_freekicks_text: string;
      penalties_order: null | number;
      penalties_text: string;
      expected_goals_per_90: number;
      saves_per_90: number;
      expected_assists_per_90: number;
      expected_goal_involvements_per_90: number;
      expected_goals_conceded_per_90: number;
      goals_conceded_per_90: number;
      now_cost_rank: number;
      now_cost_rank_type: number;
      form_rank: number;
      form_rank_type: number;
      points_per_game_rank: number;
      points_per_game_rank_type: number;
      selected_rank: number;
      selected_rank_type: number;
      starts_per_90: number;
      clean_sheets_per_90: number;
    }[];
    teams: {
      teamLogo: string;
      gkShirtLink: string;
      shirtLink: string;
      code: number;
      draw: number;
      form: null | any;
      id: number;
      loss: number;
      name: string;
      played: number;
      points: number;
      position: number;
      short_name: string;
      strength: number;
      team_division: null | any;
      unavailable: boolean;
      win: number;
      strength_overall_home: number;
      strength_overall_away: number;
      strength_attack_home: number;
      strength_attack_away: number;
      strength_defence_home: number;
      strength_defence_away: number;
      pulse_id: number;
    }[];
    phases: {
      id: number;
      name: string;
      start_event: number;
      stop_event: number;
    }[];
    events: {
      id: number;
      name: string;
      deadline_time: string;
      release_time: string;
      average_entry_score: number;
      finished: boolean;
      data_checked: boolean;
      highest_scoring_entry: number;
      deadline_time_epoch: number;
      deadline_time_game_offset: number;
      highest_score: number;
      is_previous: boolean;
      is_current: boolean;
      is_next: boolean;
      cup_leagues_created: boolean;
      h2h_ko_matches_created: boolean;
      ranked_count: number;
      chip_plays: {
        chip_name: string;
        num_played: number;
      }[];
      most_selected: number;
      most_transferred_in: number;
      top_element: number;
      top_element_info: {
        id: number;
        points: number;
      };
      transfers_made: number;
      most_captained: number;
      most_vice_captained: number;
    }[];
    total_players: number;
    element_types: {
      id: number;
      plural_name: "Goalkeepers" | "Defenders" | "Midfielders" | "Forwards";
      plural_name_short: "GKP" | "DEF" | "MID" | "FWD";
      singular_name: "Goalkeeper" | "Defender" | "Midfielder" | "Forward";
      singular_name_short: "GKP" | "DEF" | "MID" | "FWD";
      squad_select: number;
      squad_min_select: null;
      squad_max_select: null;
      squad_min_play: number;
      squad_max_play: number;
      ui_shirt_specific: true;
      sub_positions_locked: number[];
      element_count: number;
    }[];
    element_stats: {
      label: string;
      name: string;
    }[];
  };
}

export type FILED_PLAYER_TYPES = "GK" | "DEF" | "MID" | "ATT";
export type IPositionedPlayers<T> = Record<FILED_PLAYER_TYPES | "SUBS", T>;
export type ITransfersTeamPositionedPlayers<T> = Record<FILED_PLAYER_TYPES, T>;
