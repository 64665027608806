import { SyntheticEvent } from "react";
import { Tab, Tabs } from "@mui/material";
import { css } from "@emotion/react";
import { transition_all } from "../config/styles";
import { secondaryBgColor } from "../config/theme";
import { Link } from "react-router-dom";
import { AppTooltip, Button } from ".";

interface IAppTabs {
  selectedColor?: string;
  value: string | undefined;
  tabs:
    | {
        label: string;
        value: string;
        img?: string;
        toolTipTitle?: string;
        toolTipSubTitle?: string;
      }[]
    | undefined;
  isLinkTab?: boolean;
  handleTabsChange?: (
    event:
      | SyntheticEvent<Element, Event>
      | React.MouseEventHandler<HTMLButtonElement>,
    value: any
  ) => void;
}

/** Component the implement the default styles of the Tabs in the app */
export const AppTabs = ({ isLinkTab = false, ...props }: IAppTabs) => {
  const TabComp = isLinkTab ? Link : undefined;
  return (
    <Tabs
      css={[tabsStyle(props.selectedColor), !props.tabs && loadingStyles]}
      value={props.value || false} // Allow undefined to be selected
      onChange={props.handleTabsChange}
    >
      {props.tabs ? (
        props.tabs.map((tab, index) => (
          <Tab
            LinkComponent={TabComp}
            key={index}
            label={tab.label}
            value={tab.value}
            component={() => (
              <Button
                onClick={
                  props.handleTabsChange
                    ? (e) => props.handleTabsChange!(e, tab.value)
                    : undefined
                }
                LinkComponent={TabComp}
                to={tab.value}
                className={`MuiButtonBase-root ${
                  props.value === tab.value ? "Mui-selected" : ""
                }`}
                css={tabsStyle(props.selectedColor)}
              >
                {tab.img ? (
                  <img
                    className="MuiTab-iconWrapper"
                    src={tab.img}
                    alt={tab.label}
                  />
                ) : null}

                {tab.label}

                {tab.toolTipTitle && tab.toolTipSubTitle && (
                  <AppTooltip
                    title={tab.toolTipTitle}
                    subTitle={tab.toolTipSubTitle}
                  />
                )}
              </Button>
            )}
          />
        ))
      ) : (
        <AppTabsSkeleton />
      )}
    </Tabs>
  );
};

const AppTabsSkeleton = () => {
  return (
    <>
      {Array.from({ length: 3 }, (_, index) => (
        <Tab
          sx={{ height: "2.5rem", maxWidth: "6rem", borderRadius: "99999px" }}
          key={index}
        />
      ))}
    </>
  );
};

const loadingStyles = css`
  .MuiButtonBase-root {
    width: 6rem;
    background-color: #222232;
  }
`;

const tabsStyle = (selectedColor?: string) => css`
  .MuiTabs-flexContainer {
    gap: 0.5rem;
    overflow: auto;
  }

  .MuiButtonBase-root {
    ${transition_all}
    color: white !important;
    border-radius: 99999px;

    min-width: auto;
    min-height: auto;
    height: 2.5rem;
    padding: 0 1rem;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &.Mui-selected {
      gap: 0.3rem;
      background-color: ${selectedColor || "#222232"};
      :hover {
        background-color: ${selectedColor || "#222232"};
      }
    }

    .MuiTab-iconWrapper {
      ${transition_all}
      margin: 0;
      opacity: 0;
      visibility: hidden;
      width: 0;
    }

    &.Mui-selected > .MuiTab-iconWrapper {
      opacity: 100%;
      visibility: visible;
      width: 1.2rem;
      height: 1.2rem;
      object-fit: cover;
    }

    :hover {
      background-color: ${secondaryBgColor};
    }
  }

  .MuiTabs-indicator {
    display: none;
  }
`;
