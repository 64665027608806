import { css } from "@emotion/react";
import { Stack, Typography } from "@mui/material";
import goal from "../assets/view-team/goal.svg";
import midField from "../assets/view-team/mid-field.svg";
import { FPL_CHIPS_KEYS_TYPE, FPL_CHIPS } from "../constants/app.constants";
import { PlayerSkelton, Skeleton } from ".";
import { bgColor, bgColorDark } from "../config/theme";

interface IFootballFieldProps {
  children: React.ReactNode;
  showMidField?: boolean;
  activeChips: FPL_CHIPS_KEYS_TYPE | null;
}

export const FootballField = (props: IFootballFieldProps) => {
  return (
    <Stack css={teamFormationContainer}>
      {props.activeChips && (
        <Typography css={playedChipStyles}>
          {FPL_CHIPS[props.activeChips].img && (
            <img src={FPL_CHIPS[props.activeChips].img} />
          )}
          {FPL_CHIPS[props.activeChips].title}
        </Typography>
      )}
      <Stack component={"ul"} css={rootStyles}>
        <img css={goalStyles} src={goal} alt="goal" />
        {props.showMidField && (
          <img src={midField} alt="mid Field" css={midFieldStyles} />
        )}
        {props.children}
      </Stack>
    </Stack>
  );
};

export const FootballFieldSkeleton = () => {
  return (
    <Stack
      css={[
        teamFormationContainer,
        css`
          background-color: ${bgColorDark};
        `,
      ]}
    >
      <Typography css={playedChipStyles}>
        <Skeleton />
      </Typography>
      <TeamFormationSkeleton />
    </Stack>
  );
};

export const TeamFormationSkeleton = () => {
  let positionedPlayer = {
    GK: Array.from({ length: 1 }, () => ({ field_position: "GK" })),
    DEF: Array.from({ length: 4 }, () => ({ field_position: "DEF" })),
    MID: Array.from({ length: 4 }, () => ({ field_position: "MID" })),
    ATK: Array.from({ length: 2 }, () => ({ field_position: "ATK" })),
    SUBS: [
      { field_position: "GK" },
      { field_position: "DEF" },
      { field_position: "MID" },
      { field_position: "ATK" },
    ],
  };

  return (
    <Stack component={"ul"} css={[rootStyles, skeletonRootStyles]}>
      <img css={goalStyles} src={goal} alt="goal" />
      {Object.entries(positionedPlayer).map(
        ([position, playersType], playerRowIndex) => (
          <Stack component={"li"} key={position} css={playerRowStyle}>
            {playerRowIndex === 4 ? (
              <img src={midField} alt="mid Field" />
            ) : null}
            {playersType.map((_, playerIndex) => (
              <PlayerSkelton key={playerIndex} />
            ))}
          </Stack>
        )
      )}
    </Stack>
  );
};

const rootStyles = css`
  position: relative;
  isolation: isolate;

  border-radius: 1rem;
  overflow: hidden;
  background: repeating-linear-gradient(
    #22c55e 0px,
    #22c55e 5%,
    #38cb6e 5%,
    #38cb6e 10%
  );

  box-shadow: 0 0 0 clamp(5px, 1vw, 10px) #38cb6e;
  border: clamp(3px, 0.4vw, 7px) solid white;
  border-bottom: none;
`;

const goalStyles = css`
  position: absolute;
  padding: inherit;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
`;

const midFieldStyles = css`
  position: absolute;
  padding: inherit;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  translate: 0 50%;
`;

const teamFormationContainer = css`
  border-radius: 1rem;
  background-color: white;
  overflow: hidden;
  width: 100%;
  max-width: 45rem;
  margin-inline: auto;
  z-index: 1;
`;

const playedChipStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: #414141;
  width: 100%;
  margin-inline: auto;
  text-align: center;
  padding: 1rem;
  border-radius: 1rem;
  font-size: clamp(1.2rem, 3vw, 2rem);
  font-weight: 700;

  img {
    width: 2rem;
    height: 2rem;
  }
`;

const skeletonRootStyles = css`
  --bg-color: #111120;
  --bg-color-light: ${bgColor};
  background: repeating-linear-gradient(
    var(--bg-color) 0px,
    var(--bg-color) 5%,
    var(--bg-color-light) 5%,
    var(--bg-color-light) 10%
  );

  box-shadow: 0 0 0 clamp(5px, 1vw, 10px) #27273f;
  opacity: 0.5;
`;

const playerRowStyle = css`
  flex: 1;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding-block: clamp(0.7rem, 1vw, 1.5rem);

  &:last-of-type {
    position: relative;
    z-index: -100;
    padding-block: clamp(1.2rem, 2.5vw, 3rem);
    background-color: #f0f8ff7e;

    & > img:first-of-type {
      position: absolute;
      z-index: -1;
      top: 0;
      translate: 0 -50%;
      width: 100%;
    }
  }
`;
