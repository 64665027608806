import { createContext, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ERROR_MESSAGES } from "../config/contextApi.constants";
import {
  ITransfersEplPlayerInfo,
  useGetTransfersEplPlayerInfo,
} from "../utils/hooks/useGetTransfersEplPlayerInfo";

interface TableState {
  searchQuery: string;
  sortConfig: {
    field: keyof ITransfersEplPlayerInfo["eplPlayers"][number];
    direction: "asc" | "desc";
  };
  selectedColumns: string[];
  teams: string[];
  priceRange: { min: number; max: number };
  fieldPositions: string[];
}

interface ITransferTableContext {
  transfersTableFiltersState: TableState;
  setTransfersTableFiltersState: React.Dispatch<
    React.SetStateAction<TableState>
  >;
  transfersTableRows: ITransfersEplPlayerInfo["eplPlayers"];
  setTransfersTableRows: React.Dispatch<
    React.SetStateAction<ITransfersEplPlayerInfo["eplPlayers"]>
  >;
  transferTeams: ITransfersEplPlayerInfo["teams"] | undefined;
  transferLeagueSize: number | undefined;
}

const TransferTableContext = createContext<ITransferTableContext | undefined>(
  undefined
);

export const useTransferTableContext = () => {
  const context = useContext(TransferTableContext);
  if (!context) throw new Error(ERROR_MESSAGES.NO_GLOBAL_INFO_PROVIDER);
  return context;
};

export const TransferTableContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { transfersEplPlayerInfo } = useGetTransfersEplPlayerInfo();

  const [transfersTableRows, setTransfersTableRows] = useState<
    ITransfersEplPlayerInfo["eplPlayers"]
  >([]);

  useEffect(() => {
    if (!transfersEplPlayerInfo) return;
    setTransfersTableRows(transfersEplPlayerInfo.eplPlayers);
  }, [transfersEplPlayerInfo]);

  const [searchParams, setSearchParams] = useSearchParams();
  const initialState: TableState = {
    searchQuery: searchParams.get("q") || "",
    sortConfig: JSON.parse(searchParams.get("sort") || "{}"),
    selectedColumns: JSON.parse(searchParams.get("columns") || "[]"),
    teams: [],
    priceRange: { min: 0, max: 100 },
    fieldPositions: [],
  };
  const [transfersTableFiltersState, setTransfersTableFiltersState] =
    useState<TableState>(initialState);

  // Update URL when state changes
  useEffect(() => {
    searchParams.set("q", transfersTableFiltersState.searchQuery);
    searchParams.set(
      "sort",
      JSON.stringify(transfersTableFiltersState.sortConfig)
    );
    searchParams.set(
      "columns",
      JSON.stringify(transfersTableFiltersState.selectedColumns)
    );
    setSearchParams(searchParams, { replace: true });
  }, [transfersTableFiltersState, setSearchParams]);

  useEffect(() => {
    if (!transfersEplPlayerInfo) return;

    let filteredRows: ITransfersEplPlayerInfo["eplPlayers"] =
      transfersEplPlayerInfo.eplPlayers;
    if (transfersTableFiltersState.searchQuery) {
      const searchQuery = transfersTableFiltersState.searchQuery.toLowerCase();
      filteredRows = filteredRows.filter(
        (row) =>
          row.web_name.toLowerCase().includes(searchQuery) ||
          row.first_name.toLowerCase().includes(searchQuery) ||
          row.second_name.toLowerCase().includes(searchQuery) ||
          row.teamName.toLowerCase().includes(searchQuery)
      );
    }

    if (transfersTableFiltersState.teams.length > 0) {
      filteredRows = filteredRows.filter((row) =>
        transfersTableFiltersState.teams.includes(row.teamName)
      );
    }

    if (
      transfersTableFiltersState.priceRange.min > 0 ||
      transfersTableFiltersState.priceRange.max < 100
    ) {
      filteredRows = filteredRows.filter(
        (row) =>
          row.price >= transfersTableFiltersState.priceRange.min &&
          row.price <= transfersTableFiltersState.priceRange.max
      );
    }

    if (transfersTableFiltersState.fieldPositions.length > 0) {
      filteredRows = filteredRows.filter((row) =>
        transfersTableFiltersState.fieldPositions.includes(row.position)
      );
    }

    const sortConfig = transfersTableFiltersState.sortConfig;
    if (sortConfig.field) {
      filteredRows.sort((a, b) => {
        // @ts-ignore
        const compare = a[sortConfig.field] - b[sortConfig.field];
        if (compare !== 0) {
          return sortConfig.direction === "asc" ? compare : -compare;
        }
        return 0;
      });
    }

    setTransfersTableRows(filteredRows);
  }, [transfersTableFiltersState]);

  const value = {
    transfersTableFiltersState,
    setTransfersTableFiltersState,
    transfersTableRows,
    setTransfersTableRows,

    transferTeams: transfersEplPlayerInfo?.teams,
    transferLeagueSize: transfersEplPlayerInfo?.leagueSize,
  };

  return (
    <TransferTableContext.Provider value={value}>
      {children}
    </TransferTableContext.Provider>
  );
};
