import { IPositionedPlayers } from "../utils/types/types";

export const VIEW_TEAM_PAGE = {
  SQUAD_VALUE: "Squad Value",
  MONEY_IN_THE_BANK: "Money in the bank",
  ALL_SEASON_TRANSFERS: "All season transfers",
  POINTS_THIS_GW: "Points this GW",
  OVERALL_POINTS: "Overall points",
  OVERALL_RANK: "Overall Rank",
  GW_RANK: "GW rank",
  RANK_DIFFERENCE: "Rank Difference",
  TRANSFERS: "Transfers",
  GW_POINTS: "GW Points",
  OVERALL_RANK_LABEL: "Overall Rank",
  VIEW_MORE: "View More >",
  PATH_SYMBOL: ">",
  POINTS_SUFFIX: " pts",
  TRANSFERS_COST_PREFIX: "( ",
  TRANSFERS_COST_SUFFIX: ")",
  NUMBER_OF_PLAYERS: "Number of Players",
  TRANSFERS_BTN: {
    TITLE: "Show transfers",
    SUBTITLE: "Click here to see more transfers",
  },
  NAV_BUTTONS: {
    VIEW_TEAM: "Points",
    PICK_TEAM: "Pick team",
    MAKE_TRANSFERS: "Transfers",
  },
};

export const tabsConstants = {
  fixture: "fixture",
  history: "history",
  ownership: "ownership",
};

export const EPL_PLAYER_SUMMARY = {
  TITLE: "Player Summary",
  BTN: "Show more",
  POSITION: {
    GK: "Goal Keeper",
    DEF: "Defender",
    MID: "Midfielder",
    ATT: "Attacker",
  },
  STATS_TABLE: {
    ROW_HEAD: "Stats",
    VALUE: "Values",
    POINTS: "Points",
  },
  TABLE_TITLES: {
    THIS_SEASON: "This Season",
    PAST_SEASON: "Previous Season",
    OWNERSHIP: "Ownership by managers",
  },
  TABS: [
    {
      label: "History",
      value: tabsConstants.history,
    },
    {
      label: "Fixtures",
      value: tabsConstants.fixture,
    },
    {
      label: "Ownership",
      value: tabsConstants.ownership,
    },
  ],
  TBC: {
    TITLE: "TBC",
    TOOL_TIP: "The date of the fixture is yet to be confirmed",
  },
};

export const PICK_TEAM_PAGE_CONSTANTS = {
  META: {
    TITLE: "Pick Team",
  },
  TITLE: "Pick team",
  BTN_TITLE: "Start Picking",
  MODAL_ACTIONS: {
    SWITCH: "Switch",
    CAP: "Set as Captain",
    VC: "Set as Vice Captain",
  },
  CONFIRM_BTNS: {
    CANCEL: "Reset team",
    CONFIRM: "Confirm team",
  },

  CANCEL_MODAL_CONTENT: {
    TITLE: "Reset Chip played?",
    DESCRIPTION: "Resetting your team will also reset the chip you played",
    CONFIRM_BTN: "Reset",
    CANCEL_BTN: "Cancel",
  },
};

export const FIELD_INFO: Omit<
  IPositionedPlayers<{ MIN: number; MAX: number }>,
  "SUBS"
> = {
  ATT: { MIN: 1, MAX: 3 },
  DEF: { MIN: 3, MAX: 5 },
  MID: { MIN: 3, MAX: 5 },
  GK: { MIN: 1, MAX: 1 },
};

export const TEAMS_PAGE_TABS = {
  UPCOMING: "Upcoming",
  HISTORY: "History",
  OWNERSHIP: "Ownership",
} as const;

export const MAKE_TRANSFERS_PAGE_CONTENT = {
  META: {
    TITLE: "Make transfers",
  },
  TITLE: "Make transfers",
  MODAL_ACTIONS: {
    TRANSFER: "Transfer Out",
  },
};
