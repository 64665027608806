export const MY_TEAM_SUMMARY = {
  TITLE: "My Team | GW",
  POINTS: "Points",
  CAPTAIN: {
    TITLE: "Captain:",
    POINTS: "Captain Points:",
  },
  BTN_TITLE: "View Team",
};

export const MINI_LEAGUE_SUMMARY = {
  TITLE: "Mini League | GW",
  LINK_TITLE: "View all Table >",
  ERROR_CARD: {
    TITLE: "Mini League | GW",
    ERR_MESSAGE: "The league standings are only available for the current GW",
  },
};

export const HOME_PAGE = {
  META: {
    TITLE: "Home",
  },
  FIXTURES_SUMMARY: {
    TITLE: "Fixtures | GW",
    LINK_TEXT: "View all Fixtures >",
    FULL_TIME: "FT",
    TODAY: "Today",
  },
};

export const TRANSFERS_WIDGET = {
  BTN: (tNumber: number) =>
    `Show ${tNumber} more transfer${tNumber > 1 ? "s" : ""}`,
  CTA_CARD_CONTENT: {
    TITLE: "Explore All Transfers",
    SUBTITLE: "View all the other transfer masterminds",
    BTN: "Show all transfers",
  },
};

import chart from "../assets/cool-feature/chart.svg";
import mostHurtful from "../assets/cool-feature/most-hurtful.svg";
import tshirt from "../assets/cool-feature/tshirt.svg";
export const HOME_PAGE_EXTRA_FEATURES = {
  TITLE: "Check our other cool features",
  BTN: "View more",
  MORE_FEATURE: "+ Many more feature 🔥",
  FEATURES: [
    {
      title: "Discover FPL highlights",
      img: chart,
    },
    {
      title: "Fixtures Player Ownership",
      img: tshirt,
    },
    {
      title: "Most Hurtful Players",
      img: mostHurtful,
    },
  ],
};
