import { Button } from "../../../components";
import { Stack } from "@mui/material";
import {
  FIELD_INFO,
  PICK_TEAM_PAGE_CONSTANTS,
} from "../../../constants/view-team-pages.constants";
import { useSearchParams } from "react-router-dom";
import { QUERY_PARAMS } from "../../../config/routes";
import { css } from "@emotion/react";
import { useTeamContext } from "../../../context/TeamContext";

export const EplPlayerPickActions = () => {
  const [searchParams] = useSearchParams();
  const {
    closeEplPlayerModal,
    setPickTeam,
    pickTeam,
    setSwitchPlayers,
    pickTeamPositionedPlayers,
  } = useTeamContext();

  const cap = pickTeam?.picks.find((p) => p.is_captain);
  const vc = pickTeam?.picks.find((p) => p.is_vice_captain);

  const player = pickTeam?.picks.find(
    (p) =>
      p.element.toString() ===
      searchParams.get(QUERY_PARAMS.EPL_PLAYER_INFO_MODAL)
  );

  const isCap = player?.element === cap?.element;
  const isVc = player?.element === vc?.element;

  const handleCapClick = async () => {
    if (!pickTeam) return;
    if (isCap) return;

    const newPicks = pickTeam.picks.map((teamPlayer) => {
      const isPlayer = teamPlayer.element === player?.element;

      if (isVc && teamPlayer.is_captain) {
        return {
          ...teamPlayer,
          is_vice_captain: true,
          is_captain: false,
        };
      }

      return {
        ...teamPlayer,
        is_captain: isPlayer,
        is_vice_captain: isPlayer ? false : teamPlayer.is_vice_captain,
      };
    });

    setPickTeam((prev) => ({
      ...prev!,
      picks: newPicks,
    }));

    closeEplPlayerModal();
  };

  const handleVcClick = () => {
    if (!pickTeam) return;
    if (isVc) return;

    const newPicks = pickTeam.picks.map((teamPlayer) => {
      const isPlayer = teamPlayer.element === player?.element;

      if (isCap && teamPlayer.is_vice_captain) {
        return {
          ...teamPlayer,
          is_vice_captain: false,
          is_captain: true,
        };
      }

      return {
        ...teamPlayer,
        is_vice_captain: isPlayer,
        is_captain: isPlayer ? false : teamPlayer.is_captain,
      };
    });

    setPickTeam((prev) => ({
      ...prev!,
      picks: newPicks,
    }));

    closeEplPlayerModal();
  };

  const handleSwitchClick = () => {
    if (!pickTeam) return;
    const playerId = Number(
      searchParams.get(QUERY_PARAMS.EPL_PLAYER_INFO_MODAL)!
    );

    const player = pickTeam.picks.find((p) => p.element === playerId);
    if (!player) return;

    const isBench = player.position >= 12;
    let size = pickTeamPositionedPlayers[player.field_position].length;
    let max = FIELD_INFO[player.field_position].MAX;
    let min = FIELD_INFO[player.field_position].MIN;

    const availablePlayers = pickTeam.picks
      .filter((p) => {
        // same player add for UI
        if (p.element === playerId) return true;

        // gaol keeper
        if (player.field_position === "GK") {
          return p.field_position === "GK";
        }

        // no goal keeper
        if (p.field_position === "GK") {
          return false;
        }

        // get max and min for field players
        if (isBench) {
          size = pickTeamPositionedPlayers[p.field_position].length;
          max = FIELD_INFO[p.field_position].MAX;
          min = FIELD_INFO[p.field_position].MIN;
        }

        // field player switch only with bench player
        if (!isBench && p.position < 12) return false;

        // bench player switch with other bench players
        if (isBench && p.position >= 12) return true;

        // switch with same players if min is reached
        if (size <= min) {
          return p.field_position === player.field_position;
        }

        // switch with different player if max is reached
        if (size >= max) {
          return p.field_position !== player.field_position;
        }

        return true;
      })
      .map((p) => p.element);

    if (isBench) {
      setSwitchPlayers((prev) => ({
        ...prev,
        availablePlayers,
        playerIn: player.position,
      }));
    } else {
      setSwitchPlayers((prev) => ({
        ...prev,
        availablePlayers,
        playerOut: player.position,
      }));
    }

    closeEplPlayerModal();
  };

  return (
    <Stack css={pickActionsContainerStyles}>
      <Button button="gradient" onClick={handleSwitchClick}>
        {PICK_TEAM_PAGE_CONSTANTS.MODAL_ACTIONS.SWITCH}
      </Button>
      <Stack>
        {!isCap && (
          <Button button="solid" onClick={handleCapClick}>
            {PICK_TEAM_PAGE_CONSTANTS.MODAL_ACTIONS.CAP}
          </Button>
        )}
        {!isVc && (
          <Button button="solid" onClick={handleVcClick}>
            {PICK_TEAM_PAGE_CONSTANTS.MODAL_ACTIONS.VC}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

const pickActionsContainerStyles = css`
  padding-inline: 1rem;
  gap: 0.5rem;

  div {
    flex-direction: row;
    gap: 0.5rem;

    button {
      background-color: white;
      color: black;

      :focus {
        background-color: white;
      }
    }
  }
`;
