import { useMutation, useQueryClient } from "react-query";
import { FPL_CHAMP_ENDPOINTS } from "../../config/fplChampEndpoints.constants";
import { axiosInstance } from "../../config/axios.config";
import { useUserContext } from "../../context/UserContext";
import { ITransfersTeamInfo } from "./useGetTransfersTeamInfo";
import { FPL_CHIPS_KEYS_TYPE } from "../../constants/app.constants";

interface IPostAuthenticatedPickTeamBody {
  chip: FPL_CHIPS_KEYS_TYPE | null;
  picks: {
    element: number;
    position: number;
    is_captain: boolean;
    is_vice_captain: boolean;
  }[];
}

export const useMutatePickTeam = () => {
  const queryClient = useQueryClient();
  const { user } = useUserContext();

  const postPickTeam = async ({
    chip,
    picks,
  }: {
    chip: FPL_CHIPS_KEYS_TYPE | null;
    picks: ITransfersTeamInfo["picks"];
  }) => {
    if (!user || !user.teamId) return;

    const formattedPicks: IPostAuthenticatedPickTeamBody["picks"] = picks.map(
      (pick) => ({
        element: pick.element,
        position: pick.position,
        is_captain: pick.is_captain,
        is_vice_captain: pick.is_vice_captain,
      })
    );

    const { data } = await axiosInstance.post(
      FPL_CHAMP_ENDPOINTS.POST_AUTHENTICATED_PICK_TEAM_URL(user.teamId),
      {
        chip,
        picks: formattedPicks,
      },
      { withCredentials: true }
    );

    return data;
  };

  const { mutate: postPicks } = useMutation({
    mutationFn: postPickTeam,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["useGetTransfersTeamInfo"],
      });
    },
  });

  return { postPicks };
};
