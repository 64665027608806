import { Helmet } from "react-helmet";
import { OfficialLoginSection } from "../../components";
import { OFFICIAL_LOGIN_PAGE_CONTENT } from "../../constants/auth-pages.constants";
import { FormContainer } from "./components";

/** Renders the sign in page  */
export const OfficialLoginPage = () => {
  return (
    <FormContainer>
      <Helmet>
        <title>{OFFICIAL_LOGIN_PAGE_CONTENT.META.TITLE}</title>
      </Helmet>
      <OfficialLoginSection />
    </FormContainer>
  );
};
