import { FPL_CHAMP_ENDPOINTS } from "../../config/fplChampEndpoints.constants";
import { PATH_PARAMS } from "../../config/routes";
import { axiosInstance } from "../../config/axios.config";
import { useUserContext } from "../../context/UserContext";
import { useParams, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import { FPL_CHIPS_KEYS_TYPE } from "../../constants/app.constants";
import { FILED_PLAYER_TYPES } from "../types/types";
import { getCookies } from "../cookies.utils";
export interface ITransfersTeamInfo {
  activeChip: FPL_CHIPS_KEYS_TYPE | null;
  team_info: {
    name: string;
    player_name: string;
    transfers: {
      cost: number;
      status: string;
      limit: number;
      made: number;
      bank: number;
      value: number;
    };
  };
  picks: {
    name: string;
    team: number;
    teamName: string;
    field_position: FILED_PLAYER_TYPES;
    shirt_link: string;
    gk_shirt_link: string;
    element: number;
    position: number;
    multiplier: number;
    is_captain: boolean;
    is_vice_captain: boolean;
    element_type: number;
    selling_price: number;
    purchase_price: number;
  }[];
  chips: {
    id: number;
    status_for_entry: "unavailable" | "active" | "available";
    played_by_entry: number[];
    name: FPL_CHIPS_KEYS_TYPE;
    number: number;
    start_event: number;
    stop_event: number;
    chip_type: string;
    is_pending: boolean;
  }[];
}

export const useGetTransfersTeamInfo = () => {
  const { user } = useUserContext();
  const urlTeamId = useParams()[PATH_PARAMS.TEAM_ID];
  const [searchParams] = useSearchParams();
  const cookies = getCookies();

  const queryKey = [
    "useGetTransfersTeamInfo",
    user,
    searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK),
    cookies.signedIn,
    urlTeamId,
  ];

  const getTransfersTeamInfo = async () => {
    if (!queryKey.every((item) => item)) return;
    if (user?.teamId.toString() !== urlTeamId) return;

    const { data } = await axiosInstance.get<ITransfersTeamInfo>(
      FPL_CHAMP_ENDPOINTS.GET_AUTHENTICATED_TRANSFERS_TEAM_INFO_URL(
        user!.teamId,
        searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK)!
      ),
      {
        withCredentials: true,
      }
    );
    return data;
  };

  const { data: transfersTeamInfo, isLoading } = useQuery({
    queryFn: getTransfersTeamInfo,
    queryKey,
  });

  return { transfersTeamInfo, isLoading } as const;
};
