import { css, SerializedStyles } from "@emotion/react";
import { mq } from "../../../config/styles";

/** Auth Pages form container */
export const FormContainer = ({
  children,
  extraCss,
}: {
  children: React.ReactNode;
  extraCss?: SerializedStyles;
}) => {
  return <div css={[formContainer, extraCss]}>{children}</div>;
};

const formContainer = css`
  background-color: #222232;
  width: 100%;
  min-height: 100vh;
  ${mq["md"]} {
    min-height: fit-content;
    max-width: 31.375rem;
    padding: 1.75rem 2.5rem;
    margin-inline: auto;
    margin-block: 1.375rem;
    border-radius: 3.5rem;
  }
`;
