import { Stack } from "@mui/material";
import {
  circularStyleMuiDataGridPlayerSummary,
  stackCenteredStyleMuiDataGrid,
} from "../../config/styles";
import { getDifficultyColor } from "../../utils/general.helpers";

interface IFixtureDifficultyCellProps {
  difficulty: number;
}
/** JSX for displaying fixture difficulty in EPL Player Summary table. */
export const FixtureDifficultyCell: React.FC<IFixtureDifficultyCellProps> = ({
  difficulty,
}) => {
  const { bgColor, textColor } = getDifficultyColor(difficulty);

  return (
    <Stack css={stackCenteredStyleMuiDataGrid}>
      <Stack
        style={{ backgroundColor: bgColor, color: textColor }}
        css={circularStyleMuiDataGridPlayerSummary}
      >
        {difficulty}
      </Stack>
    </Stack>
  );
};
