import tripleCapChip from "../assets/view-team/3xc.svg";
import freeHitChip from "../assets/view-team/free-hit.svg";
import wildCardChip from "../assets/view-team/wild-card.svg";
import bechBoostChip from "../assets/view-team/bench-boost.svg";
import managerChip from "../assets/view-team/assistant-manager.png";
import { ITransfersTeamInfo } from "../utils/hooks/useGetTransfersTeamInfo";

export type FPL_CHIPS_KEYS_TYPE =
  | "3xc"
  | "bboost"
  | "wildcard"
  | "freehit"
  | "manager";

export const FPL_CHIPS: Record<
  FPL_CHIPS_KEYS_TYPE,
  {
    title: string;
    img?: string;
    full_name: string;
    description: string;
    cancelAble: string;
    extraInfo?: string;
    waring?: string;
  }
> = {
  bboost: {
    title: "Bench Boost chip played!",
    img: bechBoostChip,
    full_name: "Bench Boost",
    description:
      "The points scored by your benched players in a Gameweek will be added to your total.",
    cancelAble: "It can be cancelled at anytime before the Gameweek deadline.",
  },
  "3xc": {
    title: "Triple Captain chip played!",
    img: tripleCapChip,
    full_name: "Triple Cap.",
    description:
      "The points scored by your captain will be tripled instead of doubled in a Gameweek.",
    cancelAble: "It can be cancelled at anytime before the Gameweek deadline.",
  },
  freehit: {
    title: "Free Hit chip played!",
    img: freeHitChip,
    full_name: "Free hit",
    description:
      "Make unlimited free transfers for a single Gameweek. In the next Gameweek, your squad will return to how it was at the start of the last Gameweek.",
    cancelAble:
      "This chip cannot be cancelled after you play it, unless you have not yet made a transfer this Gameweek.",
    waring: "Can only be activated with your transfers",
  },
  wildcard: {
    title: "Wildcard chip played!",
    img: wildCardChip,
    full_name: "Wild Card",
    description:
      "Make unlimited permanent transfers in a Gameweek without incurring the usual 4 point cost for each.",
    cancelAble:
      "This chip cannot be cancelled after you play it, unless you have not yet made a cost transfer this Gameweek.",
    extraInfo: "The second Wildcard will be available after Sun 29 Dec 15:00.",
    waring: "Can only be activated with your transfers",
  },
  manager: {
    title: "Assistant manager chip played!",
    img: managerChip,
    full_name: "Assistant Manager",
    description:
      "Add a manager to your team to score points for three consecutive gameweeks.",
    extraInfo:
      "The Assistant Manager chip can be used once a season. Your initial manager selection will not cost a transfer. Any further manager transfers will either cost a free transfer or -4 points if you do not have a free transfer available.",
    cancelAble:
      "This chip cannot be cancelled once you activate it and confirm a manager transfer.",
  },
};

type x = ITransfersTeamInfo["chips"][number]["status_for_entry"];
export const FPL_CHIPS_STATUS: Record<x, string> = {
  unavailable: "Unavailable",
  active: "Cancel",
  available: "Play",
};
