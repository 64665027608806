import { css } from "@emotion/react";
import { Stack, Typography } from "@mui/material";
import UnfoldMoreRoundedIcon from "@mui/icons-material/UnfoldMoreRounded";
import { useEffect, useState } from "react";
import { hideScrollbar } from "../../../config/styles";
import { AppBottomModal } from "../../../components/AppBottomModal";
import { useGetTransfersEplPlayerInfo } from "../../../utils/hooks/useGetTransfersEplPlayerInfo";
import { useTransferTableContext } from "../../../context/TransferTableContext";
import teamsFilterIcon from "../../../assets/view-team/select-team-icon.svg";
import priceFilterIcon from "../../../assets/view-team/price-icon.svg";
import fieldFilterIcon from "../../../assets/view-team/field-position-icon.svg";
import { bgColorDark } from "../../../config/theme";
import attackIcon from "../../../assets/view-team/atk-icon.svg";
import midfieldIcon from "../../../assets/view-team/mid-icon.svg";
import defenseIcon from "../../../assets/view-team/def-icon.svg";
import goalieIcon from "../../../assets/view-team/gk-icon.svg";

const filterKeys = {
  teams: "teams",
  priceRange: "priceRange",
  fieldPositions: "fieldPositions",
} as const;

type FilterKey = keyof typeof filterKeys;

const initialFilters: {
  [key in FilterKey]: {
    filterKey: key;
    title: string;
    options: {
      title: string;
      value?: string;
      image: string;
    }[];
    selected: string[];
    defaultOption: string;
    image: string;
  };
} = {
  [filterKeys.teams]: {
    filterKey: "teams",
    title: "Teams",
    options: [],
    selected: [],
    defaultOption: "All",
    image: teamsFilterIcon,
  },
  [filterKeys.priceRange]: {
    filterKey: "priceRange",
    title: "Price",
    options: [],
    selected: [],
    defaultOption: "All",
    image: priceFilterIcon,
  },
  [filterKeys.fieldPositions]: {
    filterKey: "fieldPositions",
    title: "Field Positions",
    options: [
      {
        title: "Goalkeeper",
        value: "GK",
        image: goalieIcon,
      },
      {
        title: "Attack",
        value: "ATK",
        image: attackIcon,
      },

      {
        title: "Midfield",
        value: "MID",
        image: midfieldIcon,
      },

      {
        title: "Defense",
        value: "DEF",
        image: defenseIcon,
      },
    ],
    selected: [],
    defaultOption: "All",
    image: fieldFilterIcon,
  },
};

export const TransfersTableFilterMenu = () => {
  const [filters, setFilters] = useState<typeof initialFilters>(initialFilters);
  const [openFilters, setOpenFilters] = useState<(typeof filters)[FilterKey]>();
  const { transfersEplPlayerInfo } = useGetTransfersEplPlayerInfo();
  const { transfersTableFiltersState, setTransfersTableFiltersState } =
    useTransferTableContext();

  const handleTeamClick = (teamName: string) => {
    setOpenFilters((prev) => {
      const isSelected = prev!.selected.includes(teamName);
      return {
        ...prev!,
        selected: isSelected
          ? prev!.selected.filter((item) => item !== teamName) // Remove if exists
          : [...prev!.selected, teamName], // Add if doesn't exist
      };
    });
  };

  const handleApplyClick = (key: FilterKey) => {
    setTransfersTableFiltersState((prev) => ({
      ...prev,
      [key]: openFilters?.selected,
    }));
    setOpenFilters(undefined);
  };

  useEffect(() => {
    if (!transfersEplPlayerInfo) return;

    setFilters((prev) => ({
      ...prev,
      [filterKeys.teams]: {
        ...prev[filterKeys.teams],
        options: transfersEplPlayerInfo.teams.map((team) => ({
          title: team.name,
          image: team.teamLogo,
        })),
      },
    }));
  }, [transfersEplPlayerInfo]);

  return (
    <Stack css={filtersContainerStyles}>
      {Object.values(filters).map((filter) => {
        const filterInfo = transfersTableFiltersState[
          filter.filterKey
        ] as string[];

        return (
          <button
            key={filter.filterKey}
            css={[
              FilterItemStyle,
              filterInfo.length > 0 &&
                css`
                  background-color: white;
                  color: ${bgColorDark};
                `,
            ]}
            onClick={() => setOpenFilters({ ...filter, selected: filterInfo })}
          >
            <span className="title">{filter.title}</span>
            <span className="selected">
              {filterInfo.length === 1
                ? filterInfo[0]
                : filterInfo.length > 1
                ? "Multiple teams selected"
                : filter.defaultOption}
            </span>
            <UnfoldMoreRoundedIcon />
          </button>
        );
      })}

      <AppBottomModal
        iconImage={openFilters?.image}
        title={openFilters?.title}
        isOpen={!!openFilters}
        onClose={() => setOpenFilters(undefined)}
        onApplyClick={() => handleApplyClick(openFilters!.filterKey)}
        disabledApply={
          openFilters &&
          JSON.stringify(openFilters.selected) ===
            JSON.stringify(transfersTableFiltersState[openFilters.filterKey])
        }
      >
        <Stack css={containerStyles}>
          {openFilters?.options.map((option) => (
            <Stack
              key={option.title}
              css={[
                teamStyles,
                openFilters?.selected.length > 0 &&
                  !openFilters?.selected.includes(
                    option.value || option.title
                  ) &&
                  css`
                    opacity: 0.5;
                  `,
              ]}
              onClick={() => handleTeamClick(option.value || option.title)}
            >
              <img src={option.image} alt={option.title} />
              <Typography>{option.title}</Typography>
            </Stack>
          ))}
        </Stack>
      </AppBottomModal>

      {/* <Menu
        css={menuStyles}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {openFilters &&
          openFilters.options.map((option) => (
            <MenuItem
              key={option}
              value={option}
              data-value={option}
              css={option === openFilters.selected ? selectedStyles : null}
              onClick={handleMenuItemCLick}
            >
              {option}
            </MenuItem>
          ))}
      </Menu> */}
    </Stack>
  );
};

const containerStyles = css`
  flex: 1;
  gap: 1rem;
  overflow-y: auto;
`;

const teamStyles = css`
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  font-weight: 600;
  font-size: 1.2rem;

  img {
    width: 3rem;
    height: 3rem;
  }
`;

const filtersContainerStyles = css`
  flex-direction: row;

  gap: 0.5rem;
  max-width: 100%;
  overflow-x: auto;
  ${hideScrollbar}
`;

const FilterItemStyle = css`
  border: none;
  color: inherit;
  background-color: #222232;
  cursor: pointer;

  border-radius: 0.75rem;
  padding: 0.5rem 1rem;
  width: fit-content;

  display: flex;
  gap: 0.5rem;
  font-size: 1rem;
  align-items: center;
  justify-content: center;

  .title {
    font-weight: 900;
  }

  .selected {
    font-weight: 400;
    white-space: nowrap;
  }

  svg {
    font-size: 1rem;
  }
`;

// const menuStyles = css`
//   --spacing: 0.25rem;

//   .MuiPaper-root {
//     background: transparent;
//     margin-top: 0.5rem;
//   }

//   .MuiList-root {
//     isolation: isolate; // for li selected border

//     color: white;
//     background-color: #2a2b3d;
//     width: 200px;
//     border-radius: 0.75rem;
//     padding: 1rem var(--spacing) 0.5rem;

//     display: flex;
//     flex-direction: column;
//     gap: var(--spacing);

//     max-height: 300px;
//     overflow-y: auto;

//     li {
//       display: flex;
//       align-items: center;
//       justify-content: center;

//       font-size: inherit;
//       font-weight: 500;
//       text-align: center;

//       height: 1rem !important;
//       padding: 0;
//       border-radius: 0.5rem;
//       background-color: #181829;
//     }
//   }
// `;

// const selectedStyles = css`
//   position: relative;
//   overflow: visible;

//   ::before {
//     ${primaryGradient}
//     content: "";
//     display: block;
//     position: absolute;
//     top: -2px;
//     left: -2px;
//     bottom: -2px;
//     right: -2px;
//     border-radius: inherit;
//     z-index: -1;
//   }
// `;
