import { css } from "@emotion/react";
import { Stack } from "@mui/material";
import { TeamNavigationTabs, TransfersDeadline } from "../../components";
import { Helmet } from "react-helmet";
import {
  ChipsSection,
  ConfirmChangesSection,
  EplPlayerPickModal,
  MakeTransfersTabs,
  PickTeamFormation,
  TeamPagesHeader,
} from "./components";
import { useSwitchPlayers, useTeamContext } from "../../context/TeamContext";
import { QUERY_PARAMS } from "../../config/routes";
import { useSearchParams } from "react-router-dom";
import { PICK_TEAM_PAGE_CONSTANTS } from "../../constants/view-team-pages.constants";

export const PickTeamPage = () => {
  const { pickTeam, closeEplPlayerModal } = useTeamContext();
  const [searchParams] = useSearchParams();

  useSwitchPlayers();

  return (
    <Stack css={rootContainerStyles}>
      <Helmet>
        <title>{`${PICK_TEAM_PAGE_CONSTANTS.META.TITLE} - ${pickTeam?.team_info.name}`}</title>
      </Helmet>

      <TeamPagesHeader
        teamData={
          pickTeam && {
            name: `${PICK_TEAM_PAGE_CONSTANTS.TITLE} - ${pickTeam.team_info.name}`,
            fpl_manager_name: pickTeam.team_info.player_name,
          }
        }
      />

      <hr css={dividerStyles} />

      <TransfersDeadline useNextGameWeek />

      <ChipsSection />

      <TeamNavigationTabs />

      <MakeTransfersTabs />

      <PickTeamFormation />

      <ConfirmChangesSection />

      <EplPlayerPickModal
        isPickTeam
        open={!!searchParams.get(QUERY_PARAMS.EPL_PLAYER_INFO_MODAL)}
        onClose={closeEplPlayerModal}
      />
    </Stack>
  );
};

const rootContainerStyles = css`
  max-width: 50rem;
  margin-inline: auto;
  gap: 1rem;
`;

const dividerStyles = css`
  border-color: #838383;
  width: 100%;
`;
