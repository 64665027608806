import { css } from "@emotion/react";
import { Box, SvgIconTypeMap } from "@mui/material";
import { RankIndicatorIcon } from "./icons";
import { redColor, secondaryColor } from "../config/theme";
import { OverridableComponent } from "@mui/material/OverridableComponent";
interface IChangeIndicator {
  prevValue: number;
  currentValue: number;
  Icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  };
}

/*
 * Return an icon indicating id rank change
 * rise (green up arrow), fall (red down arrow) or Same (Gray Circle)
 */
export const ChangeIndicator = (props: IChangeIndicator) => {
  if (props.prevValue === props.currentValue) {
    return <Box component={"span"} css={grayCircleStyles} />;
  }

  const isIncreasing = props.prevValue < props.currentValue;
  const styles = {
    color: isIncreasing ? secondaryColor : redColor,
    ...(isIncreasing ? { css: rotateIconStyles } : {}),
  };

  if (props.Icon) {
    return (
      <props.Icon scale={5} css={[iconStyles(styles.color), styles.css]} />
    );
  }

  return (
    <RankIndicatorIcon
      fill={styles.color}
      css={[iconStyles(styles.color), styles.css]}
    />
  );
};

const grayCircleStyles = css`
  border-radius: 999px;
  width: 0.7rem;
  aspect-ratio: 1/1;
  background-color: #626161;
  border: 2px solid white;
`;

const rotateIconStyles = css`
  rotate: 180deg;
`;

const iconStyles = (color: string) => css`
  width: 14px;
  height: 14px;
  color: ${color};
`;
