import { useParams, useSearchParams } from "react-router-dom";
import { useTeamContext } from "../../../context/TeamContext";
import { useGetTeamInfo } from "../../../utils/hooks/useGetTeamInfo";
import { PATH_PARAMS, QUERY_PARAMS } from "../../../config/routes";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { EPL_PLAYER_SUMMARY } from "../../../constants/view-team-pages.constants";
import { EplPlayerOwnership } from "./EplPlayerOwnership";
import { css } from "@emotion/react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

export const EplPlayerModalHeader = () => {
  const { closeEplPlayerModal } = useTeamContext();
  const urlParams = useParams();
  const { teamInfo } = useGetTeamInfo({
    teamId: urlParams[PATH_PARAMS.TEAM_ID]!,
  });
  const [searchParams] = useSearchParams();

  const eplPlayer = teamInfo?.picks.find(
    (i) =>
      i.element.toString() ===
      searchParams.get(QUERY_PARAMS.EPL_PLAYER_INFO_MODAL)
  )!;

  return (
    <Stack css={rootStyles}>
      <Stack css={headerSectionStyles} paddingInline={"1rem"}>
        <Typography variant="h1">{EPL_PLAYER_SUMMARY.TITLE}</Typography>
        <IconButton onClick={closeEplPlayerModal}>
          <CloseRoundedIcon />
        </IconButton>
      </Stack>

      <span css={dividerStyles} />

      <Box paddingInline={"1rem"}>
        <EplPlayerOwnership eplPlayer={eplPlayer} />
      </Box>
    </Stack>
  );
};

const rootStyles = css`
  gap: 1rem;
`;

const headerSectionStyles = css`
  flex-direction: row;

  align-items: center;
  justify-content: space-between;
  width: 100%;

  h1 {
    font-size: clamp(1.5rem, 3vw, 2vw);
  }

  button {
    color: white;
  }
`;

const dividerStyles = css`
  background-color: #c1c1c147;
  width: 100%;
  display: block;
  margin-inline: auto;
  height: 1px;
  border-radius: 999px;
`;
