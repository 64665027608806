import { css } from "@emotion/react";
import { Stack, Typography } from "@mui/material";
import { secondaryColor } from "../../config/theme";
import { FILED_PLAYER_TYPES } from "../../utils/types/types";

interface ITransfersEplPlayerCell {
  name: string;
  position: FILED_PLAYER_TYPES;
  teamName: string;
  tShirtLink: string;
}

export const TransfersEplPlayerCell = (props: ITransfersEplPlayerCell) => {
  const { name, position, teamName, tShirtLink } = props;
  return (
    <Stack css={[eplPlayerCellStyles]}>
      <img src={tShirtLink} alt={teamName} />
      <Stack css={teamCellStyle}>
        <Typography variant="h1">{name} </Typography>
        <Typography variant="h2">
          {teamName} | {position}
        </Typography>
      </Stack>
    </Stack>
  );
};

const eplPlayerCellStyles = css`
  height: 100%;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  img {
    width: 2rem;
    height: 2rem;
  }
`;

const teamCellStyle = css`
  justify-content: center;
  height: 100%;
  white-space: normal;
  h1 {
    font-size: inherit;
    font-weight: 700;
    color: white;
    color: ${secondaryColor};
  }

  h2 {
    font-size: inherit;
  }
`;
