import { axiosInstance } from "../../config/axios.config";
import { FPL_CHAMP_ENDPOINTS } from "../../config/fplChampEndpoints.constants";
import { supabase } from "../../config/supabase";
import { IGlobalInfo } from "../types/types";
import { useIsSharedURL } from "./useIsSharedURL";
import { useQuery } from "react-query";

export const useGetGlobalInfo = () => {
  const { isShared } = useIsSharedURL();

  const getGlobalInfo = async () => {
    const {
      data: { session },
    } = await supabase.auth.getSession();

    if (!session) return;

    const { data } = await axiosInstance.get<IGlobalInfo>(
      FPL_CHAMP_ENDPOINTS.GLOBAL_INFO(isShared)
    );
    return data;
  };

  const { data: globalInfo, isLoading } = useQuery({
    queryKey: "global-info",
    queryFn: getGlobalInfo,
  });
  return { globalInfo, isLoading };
};
