import { useQuery } from "react-query";
import { axiosInstance } from "../../config/axios.config";
import { FPL_CHAMP_ENDPOINTS } from "../../config/fplChampEndpoints.constants";
import { useUserContext } from "../../context/UserContext";
import { getCookies } from "../cookies.utils";
import { useGlobalInfoContext } from "../../context/GlobalInfoContext";
import { FILED_PLAYER_TYPES, IGlobalInfo } from "../types/types";

export interface ITransfersEplPlayerInfo {
  leagueSize: number;
  eplPlayers: (IGlobalInfo["allInfo"]["elements"][number] & {
    teamName: string;
    position: FILED_PLAYER_TYPES;
    price: number;
    // eplPlayerPointsHistory: number[];
    fixturesDifficulty: {
      difficulty: number;
      op_team_name: string;
    }[];
    pickCount: number;
  })[];
  teams: (IGlobalInfo["allInfo"]["teams"][number] & {
    gkShirtLink: string;
    shirtLink: string;
    teamLogo: string;
  })[];
}

export const useGetTransfersEplPlayerInfo = () => {
  const { user } = useUserContext();
  const { selectedLeague } = useGlobalInfoContext();
  const cookies = getCookies();

  const getTransfersEplPlayerInfo = async () => {
    if (
      user?.teamId &&
      //   selectedLeague?.rank_count! <= 500 &&
      cookies.signedIn === "true"
    ) {
      const { data } = await axiosInstance.get<ITransfersEplPlayerInfo>(
        FPL_CHAMP_ENDPOINTS.GET_AUTHENTICATED_EPL_PLAYER_INFO_URL(
          selectedLeague?.id!,
          user.teamId
        ),
        {
          withCredentials: true,
        }
      );
      return data;
    }
  };

  const { data: transfersEplPlayerInfo, isLoading } = useQuery({
    queryFn: getTransfersEplPlayerInfo,
    queryKey: [
      "useGetTransfersEplPlayerInfo",
      user,
      selectedLeague,
      cookies.signedIn,
    ],
  });

  return { transfersEplPlayerInfo, isLoading } as const;
};
