import { css } from "@emotion/react";
import { Stack } from "@mui/material";
import { transition_all } from "../config/styles";
import { secondaryColor, bgColorDark } from "../config/theme";

interface SortOptionsProps {
  options: { label: string; value: string }[];
  onSortOptionChange: (value: string) => void;
  selectedOption: string;
}

export const AppSelect = ({
  options,
  onSortOptionChange,
  selectedOption,
}: SortOptionsProps) => {
  return (
    <Stack component={"ul"} css={rootContainerStyles}>
      {options.map(({ label, value }) => (
        <li
          key={value}
          onClick={() => {
            onSortOptionChange(value);
          }}
        >
          <label css={selectItemStyles}>
            <span css={radioStyles}>
              <input
                type="radio"
                name="sort"
                checked={selectedOption === value}
              />
              <span />
            </span>
            {label}
          </label>
        </li>
      ))}
    </Stack>
  );
};

const rootContainerStyles = css`
  gap: 1rem;
  display: flex;
`;

const selectItemStyles = css`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-size: 1rem;
  cursor: pointer;
`;

const radioStyles = css`
  display: inline-block;
  aspect-ratio: 1/1;
  width: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  input {
    display: none;
    :checked ~ span {
      background-color: ${secondaryColor};
      box-shadow: 0 0 0 0.25rem ${bgColorDark}, 0 0 0 0.4rem ${secondaryColor};
    }
  }

  span {
    ${transition_all}
    background: transparent;
    box-shadow: 0 0 0 0.25rem ${bgColorDark}, 0 0 0 0.4rem gray;
    display: block;
    height: 1rem;
    aspect-ratio: 1/1;
    border-radius: 50%;
  }
`;
