import { css } from "@emotion/react";
import wallet from "../../../assets/make-transfers/wallet.svg";
import { primaryGradient } from "../../../config/styles";
import { Stack, Typography } from "@mui/material";
import { Skeleton } from "../../../components";
import { useGetTransfersTeamInfo } from "../../../utils/hooks/useGetTransfersTeamInfo";

export const BankBar = () => {
  const { transfersTeamInfo } = useGetTransfersTeamInfo();

  if (!transfersTeamInfo) return <Skeleton extraStyles={commonStyles} />;

  return (
    <Stack css={bankContainerStyles}>
      <img src={wallet} />
      <Typography>{`Bank ${
        transfersTeamInfo.team_info.transfers.bank / 10
      }£`}</Typography>
    </Stack>
  );
};

const commonStyles = css`
  height: 2.2rem;
  border-radius: 0.5rem;
`;

const bankContainerStyles = css`
  ${primaryGradient}
  ${commonStyles}
  color: black;
  flex-direction: row;
  padding-inline: 0.5rem;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;

  p {
    font-weight: 700;
  }
`;
