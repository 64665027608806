/** Convert UTC date to client time zone */
export const convertDateTimeZone = (gameUTCDate: string) => {
  return new Date(
    new Date(gameUTCDate).toLocaleString("en", {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    })
  );
};

/** Game Time Formatted for Fixture results page */
export const getEplPLayerSummaryFixtureDates = (date: string) => {
  return new Date(date)
    .toLocaleString("en-us", {
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      weekday: "short",
      hour12: false,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    })
    .split(",")
    .join("");
};

/** Game Time Formatted for Fixture results page */
export const getResultsPageDate = (date: string) => {
  return new Date(date)
    .toLocaleString("en", {
      month: "long",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      year: "numeric",
      weekday: "long",
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    })
    .split(",")
    .join("")
    .split("at ")
    .join("");
};

/** Formate transfers deadline date */
export const getDeadLineDate = (date: string) => {
  return new Date(date).toLocaleString("en", {
    month: "long",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
};

/**
 * Formate time for fixture cards
 * (Time left for game start formatted, Game Date Formate,
 * and Game time formatted
 */
export const getTimeLeftForGame = (gameUTCDate: string) => {
  const oneMonth = 1000 * 60 * 60 * 24 * 30;
  const oneDay = 1000 * 60 * 60 * 24;
  const oneHour = 1000 * 60 * 60;
  const oneMinute = 1000 * 60;

  const todaysDate = new Date();

  const gameDate = convertDateTimeZone(gameUTCDate);

  const gameDayDate = gameDate
    .toLocaleString("en-GB", {
      weekday: "short",
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    })
    .split(",")
    .join(""); // en-GB is england's formate dd/mm/yy

  const kickOffTime = gameDate.toLocaleTimeString("en", { timeStyle: "short" });

  const timeDiff = gameDate.getTime() - todaysDate.getTime();

  let timeLeft = null;
  if (timeDiff > oneMinute) timeLeft = (timeDiff / oneMinute).toFixed() + "m";
  if (timeDiff > oneHour) timeLeft = (timeDiff / oneHour).toFixed() + "h";
  if (timeDiff > oneDay) timeLeft = (timeDiff / oneDay).toFixed() + "d";
  if (timeDiff > oneMonth) timeLeft = (timeDiff / oneMonth).toFixed() + "m";

  const minutesPlayed = (todaysDate.getTime() - gameDate.getTime()) / 1000 / 60;

  return { timeLeft, kickOffTime, gameDayDate, minutesPlayed };
};

/** get time left for game */
export const getTimeLeft = (gameUTCDate: string) => {
  const gameDate = convertDateTimeZone(gameUTCDate);
  const now = new Date();

  const timeDifference = gameDate.getTime() - now.getTime();

  if (timeDifference <= 0) {
    return "The game has already started or passed.";
  }

  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  // const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

  if (days > 0) {
    return `${days}d left`;
  } else if (hours < 0) {
    return `Lineups confirmed`;
  } else {
    return `${hours < 10 ? "0" + hours : hours}:${
      minutes < 10 ? "0" + minutes : minutes
    } left`;
  }
};

/** check is given date is todays date */
export const isToday = (gameUTCDate: string) => {
  const gameDate = convertDateTimeZone(gameUTCDate);
  const now = new Date();

  const timeDiff = Math.floor(
    (gameDate.getTime() - now.getTime()) / (1000 * 60 * 60 * 24)
  );
  return timeDiff <= 0;
};
