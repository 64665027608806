import { Stack, Typography } from "@mui/material";
import { PageHeader, Skeleton } from "../../../components";
import { css } from "@emotion/react";

export const TeamPagesHeader = ({
  teamData,
}: {
  teamData: { fpl_manager_name: string; name: string } | undefined;
}) => {
  return (
    <Stack>
      <PageHeader
        title={teamData && teamData.name}
        isLoading={teamData === null}
      />
      <Typography variant="h2" css={fplManagerNameStyles}>
        {teamData ? teamData.fpl_manager_name : <Skeleton width={"50%"} />}
      </Typography>
    </Stack>
  );
};

const fplManagerNameStyles = css`
  margin-top: 0.3rem;
  font-size: clamp(1rem, 3vw, 2rem);
  font-weight: 500;
`;
