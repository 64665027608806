import { createContext, useContext, useEffect, useState } from "react";
import { ERROR_MESSAGES } from "../config/contextApi.constants";
import { Outlet, useSearchParams } from "react-router-dom";
import { PATH_PARAMS, QUERY_PARAMS } from "../config/routes";
import {
  ITransfersTeamInfo,
  useGetTransfersTeamInfo,
} from "../utils/hooks/useGetTransfersTeamInfo";
import { FPL_CHIPS_KEYS_TYPE } from "../constants/app.constants";
import { useMutatePickTeam } from "../utils/hooks/useMutatePickTeam";
import { arrangePlayerInPickTeam } from "../utils/arrangePlayersOnFootBallField";
import { IPositionedPlayers } from "../utils/types/types";
import { useGlobalInfoContext } from "./GlobalInfoContext";
import { generateStepArray } from "../utils/general.helpers";
import { SESSION_STORAGE } from "../config/app.config";
import {
  GA4_EVENTS,
  TrackGoogleAnalyticsEvent,
} from "../utils/google-analytics";
import { TEAMS_PAGE_TABS } from "../constants/view-team-pages.constants";

type SortOption = { sortby: string; order: "asc" | "desc" };
type Keys = keyof typeof TEAMS_PAGE_TABS;
export type teamTabsType = (typeof TEAMS_PAGE_TABS)[Keys]; //  "myValue1" | "myValue2"

interface ITeamContext {
  selectedTab: teamTabsType;
  setSelectedTab: React.Dispatch<React.SetStateAction<teamTabsType>>;
  showLoginForm: boolean;
  setShowLoginForm: React.Dispatch<React.SetStateAction<boolean>>;
  openEplPlayerModal: (eplPlayerId: string) => void;
  closeEplPlayerModal: () => void;
  pickTeam: ITransfersTeamInfo | undefined;
  setPickTeam: React.Dispatch<
    React.SetStateAction<ITransfersTeamInfo | undefined>
  >;
  hasChanged: boolean;
  isChipPlayed: boolean;
  setIsChipPlayed: React.Dispatch<React.SetStateAction<boolean>>;
  resetPicksChanges: () => void;
  confirmPicksChanges: () => void;
  switchPlayers: ISwitchPlayers;
  setSwitchPlayers: React.Dispatch<React.SetStateAction<ISwitchPlayers>>;
  pickTeamPositionedPlayers: IPositionedPicksPlayers;
  setPickTeamPositionedPlayers: React.Dispatch<
    React.SetStateAction<IPositionedPicksPlayers>
  >;
  sortOption: SortOption;
  setSortOption: React.Dispatch<React.SetStateAction<SortOption>>;
}

interface ISwitchPlayers {
  availablePlayers: number[];
  playerIn: number | null;
  playerOut: number | null;
}

type IPositionedPicksPlayers = IPositionedPlayers<ITransfersTeamInfo["picks"]>;

const TeamContext = createContext<ITeamContext | undefined>(undefined);
export const useTeamContext = () => {
  const globalInfoState = useContext(TeamContext);
  if (!globalInfoState) throw new Error(ERROR_MESSAGES.NO_GLOBAL_INFO_PROVIDER);
  return globalInfoState;
};

export const TeamContextProvider = () => {
  const [pickTeamPositionedPlayers, setPickTeamPositionedPlayers] =
    useState<IPositionedPicksPlayers>({
      GK: [],
      DEF: [],
      MID: [],
      ATT: [],
      SUBS: [],
    });

  const [selectedTab, setSelectedTab] = useState<teamTabsType>(
    TEAMS_PAGE_TABS.UPCOMING
  );

  const [showLoginForm, setShowLoginForm] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const { transfersTeamInfo } = useGetTransfersTeamInfo();
  const [pickTeam, setPickTeam] = useState<ITransfersTeamInfo>();
  const strTransferTeamInfo = JSON.stringify(transfersTeamInfo);

  const { postPicks } = useMutatePickTeam();

  const [hasChanged, setHasChanged] = useState(false);
  const [isChipPlayed, setIsChipPlayed] = useState(false);

  const [switchPlayers, setSwitchPlayers] = useState<ISwitchPlayers>({
    availablePlayers: [],
    playerIn: null,
    playerOut: null,
  });

  const [sortOption, setSortOption] = useState<{
    sortby: string;
    order: "asc" | "desc";
  }>({
    sortby: "price",
    order: "asc",
  });

  useEffect(() => {
    if (!pickTeam) return;
    setPickTeamPositionedPlayers(arrangePlayerInPickTeam(pickTeam.picks));
  }, [pickTeam]);

  const openEplPlayerModal = (eplPlayerId: string) => {
    searchParams.set(QUERY_PARAMS.EPL_PLAYER_INFO_MODAL, eplPlayerId);
    setSearchParams(searchParams);
  };

  const closeEplPlayerModal = () => {
    searchParams.delete(QUERY_PARAMS.EPL_PLAYER_INFO_MODAL);
    setSearchParams(searchParams);
  };

  const resetPicksChanges = () => {
    if (!transfersTeamInfo) return;
    setPickTeam(transfersTeamInfo);
  };

  const confirmPicksChanges = () => {
    if (!pickTeam) return;

    const activeChip = pickTeam.chips.find(
      (chip) => chip.status_for_entry === "active"
    );

    setPickTeam(undefined);
    setHasChanged(false);
    postPicks({
      chip: activeChip ? activeChip.name : null,
      picks: pickTeam.picks,
    });
    TrackGoogleAnalyticsEvent(GA4_EVENTS.PICK_TEAM_CONFIRM_CLICK);
  };

  useEffect(() => {
    if (transfersTeamInfo) {
      setPickTeam(transfersTeamInfo);
    }
  }, [transfersTeamInfo]);

  useEffect(() => {
    if (pickTeam && strTransferTeamInfo !== JSON.stringify(pickTeam)) {
      setHasChanged(true);
    } else {
      setHasChanged(false);
    }
  }, [pickTeam]);

  const shared = {
    selectedTab,
    setSelectedTab,
    showLoginForm,
    setShowLoginForm,
    openEplPlayerModal,
    closeEplPlayerModal,
    pickTeam,
    setPickTeam,
    hasChanged,
    isChipPlayed,
    setIsChipPlayed,
    resetPicksChanges,
    confirmPicksChanges,
    switchPlayers,
    setSwitchPlayers,
    pickTeamPositionedPlayers,
    setPickTeamPositionedPlayers,
    sortOption,
    setSortOption,
  };

  return (
    <TeamContext.Provider value={shared}>
      <Outlet />
    </TeamContext.Provider>
  );
};

export const useSwitchPlayers = () => {
  const { switchPlayers, setSwitchPlayers, pickTeam, setPickTeam } =
    useTeamContext();

  useEffect(() => {
    if (!pickTeam) return;
    if (switchPlayers.playerIn != null && switchPlayers.playerOut != null) {
      let tempPlayers = [...pickTeam.picks];

      const playerIn = tempPlayers[switchPlayers.playerIn - 1];
      const playerOut = tempPlayers[switchPlayers.playerOut - 1];

      tempPlayers[switchPlayers.playerIn - 1] = {
        ...playerOut,
        is_captain: playerIn.is_captain,
        is_vice_captain: playerIn.is_vice_captain,
      };

      tempPlayers[switchPlayers.playerOut - 1] = {
        ...playerIn,
        is_captain: playerOut.is_captain,
        is_vice_captain: playerOut.is_vice_captain,
      };

      const filedPlayer = tempPlayers
        .slice(0, 11)
        .sort((playerA, playerB) => playerA.element_type - playerB.element_type)
        .map((player, index) => ({
          ...player,
          position: index + 1,
        }));

      const benchPlayer = tempPlayers.slice(11).map((player, index) => ({
        ...player,
        position: index + 11 + 1,
      }));

      tempPlayers = [...filedPlayer, ...benchPlayer];

      setPickTeam((prev) => ({ ...prev!, picks: tempPlayers }));

      setSwitchPlayers({
        playerIn: null,
        playerOut: null,
        availablePlayers: [],
      });
    }
  }, [switchPlayers]);
};

export const useTransfersTableFilters = () => {
  const { allInfo } = useGlobalInfoContext();

  const initialFilters: {
    title: string;
    defaultOption: string;
    selected: string | null;
    options: string[];
  }[] = [
    {
      title: "Price",
      defaultOption: "Unlimited",
      selected: null,
      options: [
        "Unlimited",
        ...generateStepArray(4, 12, 0.5).map((value) => `${value}$`),
      ],
    },
    {
      title: "Position",
      defaultOption: "All",
      selected: null,
      options: ["All", "Goalkeeper", "Defender", "Midfielder", "Forward"],
    },
    {
      title: "Team",
      defaultOption: "All",
      selected: null,
      options: [
        "All",
        ...(allInfo ? allInfo.teams.map((team) => team.name) : []),
      ],
    },
  ];

  const getFilters = () => {
    const filters = sessionStorage.getItem(
      SESSION_STORAGE.TRANSFERS_TABLE_FILTERS
    );
    if (filters) return JSON.parse(filters) as typeof initialFilters;
    return initialFilters;
  };

  const [filters, setFilters] = useState(getFilters);

  useEffect(() => {
    sessionStorage.setItem(
      SESSION_STORAGE.TRANSFERS_TABLE_FILTERS,
      JSON.stringify(filters)
    );
  }, [filters]);

  const selectItem = (openFilters: (typeof filters)[0], value: string) => {
    setFilters((prev) => {
      return prev.map((filter) => {
        if (filter.title === openFilters.title) {
          return {
            ...filter,
            selected: value,
          };
        }
        return filter;
      });
    });
  };

  return {
    filters,
    selectItem,
  };
};

export const useTransfersTableSort = () => {
  const { sortOption, setSortOption } = useTeamContext();

  const handleSortOptionChange = (sortby: string) => {
    setSortOption((prev) => ({ ...prev, sortby }));
  };

  const handleOrder = (e: React.MouseEvent<HTMLButtonElement>) => {
    console.log(e.currentTarget.dataset.order);
    setSortOption({
      ...sortOption,
      order: e.currentTarget.dataset.order as "asc" | "desc",
    });
  };

  return {
    sortOption,
    handleSortOptionChange,
    handleOrder,
  };
};

export const usePlayChips = () => {
  const { pickTeam, setPickTeam, isChipPlayed, setIsChipPlayed } =
    useTeamContext();

  const [searchParams] = useSearchParams();

  const activateChip = (chipName: FPL_CHIPS_KEYS_TYPE) => {
    if (!pickTeam) return;
    const newChip: ITransfersTeamInfo["chips"] = pickTeam.chips.map((chip) => {
      if (chip.name === chipName) {
        return {
          ...chip,
          status_for_entry: "active",
          played_by_entry: [
            Number(searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK)!) + 1,
          ],
        };
      } else {
        return {
          ...chip,
          status_for_entry: "unavailable",
        };
      }
    });

    setIsChipPlayed(true);
    setPickTeam({ ...pickTeam, chips: newChip, activeChip: chipName });
  };

  const deactivateChip = (chipName: FPL_CHIPS_KEYS_TYPE) => {
    if (!pickTeam) return;
    const newChip: ITransfersTeamInfo["chips"] = pickTeam.chips.map((chip) => {
      if (chip.name === chipName) {
        return {
          ...chip,
          status_for_entry: "available",
          played_by_entry: [],
        };
      } else {
        return {
          ...chip,
          status_for_entry: "available",
        };
      }
    });

    setIsChipPlayed(false);
    setPickTeam({ ...pickTeam, chips: newChip, activeChip: null });
  };

  return { isChipPlayed, activateChip, deactivateChip };
};
