import { css } from "@emotion/react";
import card6Image from "../../../assets/landing-page/card-6-img.svg";
import { Stack, Typography } from "@mui/material";
// import EastRoundedIcon from "@mui/icons-material/EastRounded";
import arrowGradient from "../../../assets/icons/arrow-gradient.svg";
import { HOME_PAGE_EXTRA_FEATURES } from "../../../constants/home-page.constants";
import { useGetUrl } from "../../../utils/hooks/useGetUrl";
import { Link } from "react-router-dom";

/**
 * A component that renders a list of extra features with links to their respective pages.
 * The features are obtained from the HOME_PAGE_EXTRA_FEATURES constant.
 * The links are obtained from the useGetUrl hook.
 * The component is a widget that displays this list of features in a card.
 * The component is used in the home page.
 */
export const ExtraFeaturesWidget = () => {
  const { getHighlightsPath, getFixturesPath, getMiniLeagueMostHurtfulPath } =
    useGetUrl();

  const featuresLinks = [
    getHighlightsPath(),
    getFixturesPath(),
    getMiniLeagueMostHurtfulPath(),
  ];

  const features = HOME_PAGE_EXTRA_FEATURES.FEATURES.map((item, index) => ({
    ...item,
    link: featuresLinks[index],
  }));

  return (
    <Stack css={cardContainerStyles}>
      <img src={card6Image} />
      <Stack css={cardContentContainer}>
        <Typography variant="h1">{HOME_PAGE_EXTRA_FEATURES.TITLE}</Typography>

        <Stack component={"ul"} css={featuresContainerStyles}>
          {features.map((item, index) => {
            return (
              <li key={index}>
                <Link to={item.link} css={featureItemStyles}>
                  <img src={item.img} />
                  <span>{item.title}</span>
                  <img src={arrowGradient} />
                </Link>
              </li>
            );
          })}
          <li css={featureItemStyles}>
            {HOME_PAGE_EXTRA_FEATURES.MORE_FEATURE}
          </li>
        </Stack>

        {/* <Button button="gradient" css={buttonStyles}>
          {HOME_PAGE_EXTRA_FEATURES.BTN}
          <EastRoundedIcon />
        </Button> */}
      </Stack>
    </Stack>
  );
};

const cardContainerStyles = css`
  padding: 1rem;
  border-radius: 1rem;
  overflow: hidden;
  position: relative;
  isolation: isolate;
  height: 100%;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
`;

const cardContentContainer = css`
  position: relative;
  height: 100%;
  gap: 1rem;
  & > h1 {
    font-size: clamp(1.5rem, 3vw, 2rem);
    font-weight: 700;
  }
`;

// const buttonStyles = css`
//   font-size: clamp(0.875rem, 1.5vw, 1.125rem);
//   font-weight: 500;
//   height: auto;
//   padding: 0.6rem 1rem !important;

//   display: flex;
//   align-items: center;
//   gap: 0.5rem;

//   svg {
//     color: inherit;
//     font-size: inherit;
//   }

//   ${mq["lg"]} {
//     padding: 0.8rem 2rem;
//   }
// `;

const featuresContainerStyles = css`
  flex: 1;
  gap: 0.75rem;
`;

const featureItemStyles = css`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: clamp(1rem, 2vw, 1.5rem);
  font-weight: 600;

  img {
    width: clamp(1rem, 2vw, 1.5rem);
  }
`;
